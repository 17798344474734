<script>
import ToggleWidget from "@/components/primoChat/ToggleWidget.vue";

export default {
  name: "Brand",
  props: {
    width: { type: Number },
    height: { type: Number },
    withChat: { type: Boolean, default: false },
  },
  components: { ToggleWidget },
};
</script>

<template>
  <div class="d-flex">
    <img src="@/assets/images/primo-lab-logo.svg" :width="$props.width" :height="$props.height"/>
    <ToggleWidget v-if="$props.withChat" />
  </div>
  <slot></slot>
</template>
