<script>
export default {
  name: 'ChatFooter'
}
</script>
<template>
  <div class="conversation__footer w-100">
    <div class="conversation__footer__actions w-100 h-100 d-flex justify-content-evenly align-items-center">
      <input type="file" ref="media" class="d-none">
      <button class="btn">
        <img src="../../assets/icons/chat/qr-code.svg" alt="qr-code" width="30">
      </button>
      <button class="btn">
        <img src="./icons/paperClip.svg" alt="paperclip" width="30">
      </button>
      <textarea type="text" autocomplete="off" class="form-control w-100" placeholder="Scrivi un messaggio"/>
      <button class="btn">
        <img class="conversation__footer__input__icon__send" src="./icons/sendFill.svg" alt="send" width="30">
      </button>
    </div>
  </div>
</template>
<style scoped>
  .conversation__footer {
  padding: 0.5rem;
  background-color: #f5f5f5;
}
.conversation__footer__actions > input {
  margin: 0 10px;
  border-radius: 10px;
}
textarea {
  resize: none;
  height: 5px;
  max-height: 70px;
}
</style>
