<script>
import { inject } from 'vue';
import { useRouter } from "vue-router";
export default {
  name: 'ChatHeader',
  emits:['toFavorite'],
  setup() {
    const router = useRouter();
    const mobileLayout = inject("mobileLayout");
    const prescription = inject("prescription");

    const redirectToConsulting = (prescriptionId) => {
       // Resolve the route
      const resolvedRoute = router.resolve({
        name: "ConsultingDashboard",
        params: { prescription_id: prescriptionId },
        query: { show_chat: true }
      });

      // Open the resolved route's URL in a new tab
      window.open(resolvedRoute.href, '_blank');
    }

    return { mobileLayout, prescription, redirectToConsulting }
  },
}
</script>
<template>
  <nav class="bg-light">
    <div class="d-flex align-items-center flex-wrap ">
      <button v-if="mobileLayout" class="btn" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse"
        aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">
        <img src="@/assets/icons/arrow-left.svg" width="60" alt="arrow-left" class="cp arrow-left" >
      </button>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 class="text-dark p-3">
          <span class="me-2" aria-current="page"> {{ prescription.clinic }}</span>
          <a class="text-decoration-none fw-bolder cp text-violet" target="_blank" @click.prevent="redirectToConsulting(prescription.id)">{{ prescription.number_text }}</a>
        </h4>
        <button
          v-if="prescription.is_favorite !== null"
          @click="$emit('toFavorite', prescription.chat_channel)"
          :class="{'me-5': !mobileLayout}"
          class="btn" type="button">
          <img :src="require(`@/assets/icons/${prescription.is_favorite ? 'heart-selected.svg' : 'heart.svg'}`)" width="35" alt="arrow-left" class="cp" >
        </button>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
img.arrow-left {
  filter: invert(0.5);
}

</style>
