<script>
import ChatMessage from './ChatMessage.vue';
import { watch, watchEffect, ref, inject } from 'vue';
import Spinner from "@/components/general/Spinner";
export default {
  name: 'ChatBody',
  components: { ChatMessage, Spinner },
  emits:['getHistory'],
  props: {
    chat: {
      type: Array,
      default: () => [],
    },
    spinner: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const conversation = ref(null);
    const haveScroll = ref(null);
    const onTheTop = ref(null);
    const onTheBottom = ref(null);
    const prescription = inject('prescription');

    const handleScroll = () => {
      onTheTop.value = (conversation.value.scrollTop === 0);
      onTheBottom.value = ((conversation.value.scrollHeight - conversation.value.scrollTop) === conversation.value.clientHeight);
      haveScroll.value = (conversation.value.clientHeight < conversation.value.scrollHeight);
    }

    const scrollToBottom = (millisecond = 500) => {
      setTimeout(() => {
        let el = conversation.value;
        if (el) {
          let scrollHeight = el.scrollHeight;
          el.scroll({ top: (scrollHeight), behavior: 'smooth' });
        }
      }, millisecond);
    };

    watch(() => props.chat, () => {
      scrollToBottom();
    })

    watchEffect(() => {
      // Infinite scroll init.
      if (conversation.value) {
        conversation.value.addEventListener("scroll", handleScroll);
      }
    });

    watch(() => onTheBottom.value, (value) => {
      if (value) {
        scrollToBottom();
      }
    });

    // Is on the top.
    watch(() => onTheTop.value, (value) => {
      if (value) {
        emit('getHistory');
      }
    });

    return {
      conversation,
      scrollToBottom,
      haveScroll,
      onTheBottom,
      onTheTop,
      prescription
    }
  }
}
</script>
<template>
  <div ref="conversation" class="conversation overflow-auto flex-grow-1">
    <div class="conversation__messages my-3">
      <div class="conversation__message__container d-flex flex-column w-100">
        <template v-if="$props.chat.length && prescription.id">
          <template v-for="(messageGroup, i) in $props.chat" :key="i">
            <div class="conversation__message__container__date d-flex justify-content-center align-items-center">
              <div class="conversation__message__date d-flex justify-content-center p-2 shadow">{{ messageGroup.date }}</div>
            </div>
            <ChatMessage v-for="(message, index) in messageGroup.messages" :key="index" :message="message" />
          </template>
          <template v-if="$props.spinner">
            <Spinner/>
          </template>
        </template>
        <template v-else>
          <h5 class="m-auto text-muted  mt-5">Seleziona una chat per continuare</h5>
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
.conversation {
  height: calc(100% - 30rem);
  background-color: #f3eef8c9;
  background-image: url('../../assets/images/primo-lab-logo.svg');
  background-color: rgba(243, 238, 248, 0.7882352941);
  background-image: url(/img/primo-lab-logo.09ada58d.svg);
  background-position: center;
  background-blend-mode: lighten;
  background-size: 25rem;
  background-repeat: no-repeat;
}

.conversation__message__badge__new__message {
  bottom: 60px;
  padding: 5px;
}

.conversation__message__badge__icon__caretdown {
  &:hover {
    cursor: pointer;
  }
}

.end {
  align-items: end;
}

.start {
  align-items: start;
}

button {
  border: 0;
  padding: 0;
  background: unset;
}

.media:hover {
  cursor: pointer;
}

.info {
  background-color: #138496 !important;
  color: white !important;
}

.warning {
  background-color: #fade2d !important;
  color: white !important;
}

.alert {
  background-color: rgb(233, 123, 134) !important;
  color: white !important;
}
</style>
