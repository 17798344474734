import moment from "moment/moment";
export function calcWeekDays(startDate, endDate) {
  let day = startDate;
  let weekDays = 0;

  while (day.isSameOrBefore(endDate,'day')) {
    if (!day.isSame(moment(),'day') && (day.isoWeekday() === 6 || day.isoWeekday() === 7)) weekDays++;
    day.add(1,'d');
  }

  return weekDays;
}
