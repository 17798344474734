<script>
import { inject } from "vue";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
import { hasRole } from "@/use/utilities/users/hasRole";

export default {
  name: "ControlBar",
  emits: ['selectChatType'],
  props: {
    counter: {
      type: Number,
      required: true,
    }
  },
  setup() {
    const mobileLayout = inject("mobileLayout");
    const chatType = inject('chatType');
    const { departments } = inject("constants");

    return {
      mobileLayout,
      chatType,
      hasRole,
      isCurrentDepartment,
      departments
    }
  }
}
</script>
<template>
  <div class="d-flex control-bar justify-content-between mb-3"
    :class="{ 'h-100 justify-content-between': !mobileLayout }">
    <button class="btn close-modal" data-bs-dismiss="modal">
      <img src="@/assets/icons/close.svg" alt="paperclip" width="50">
    </button>
    <ul class="nav nav-pills nav-flush text-center flex-nowrap" :class="{ 'flex-column': !mobileLayout }">
      <button  style="width: 5rem" class="btn mx-2 position-relative  d-flex align-items-center flex-column"
        :class="{ 'active': chatType === 'auth_user_chat' }"
        @click="$emit('selectChatType', 'auth_user_chat')" :disabled="chatType === 'auth_user_chat'">
        <img src="./icons/userChat.svg" alt="paperclip" width="30">
        <span v-if="chatType === 'auth_user_chat'" class="badge bg-secondary position-absolute translate-middle" style="left: 85%">{{ counter }}</span>
        <small>Personali</small>
      </button>
      <button  style="width: 5rem" class="btn mx-2 position-relative  d-flex align-items-center flex-column"
        :class="{ 'active': chatType === 'favorite_chat' }"
        @click="$emit('selectChatType', 'favorite_chat')"
        :disabled="chatType === 'favorite_chat'">
        <img src="./icons/favoriteChat.svg" alt="paperclip" width="30">
        <span v-if="chatType === 'favorite_chat'" class="badge bg-secondary position-absolute translate-middle" style="left: 85%">{{ counter }}</span>
        <small>Preferiti</small>
      </button>
      <button v-if="hasRole('manager') || isCurrentDepartment(departments.AMMINISTRAZIONE)" style="width: 5rem" class="btn mx-2 position-relative d-flex align-items-center flex-column"
        :class="{ 'active': chatType === 'all_chat' }"
        @click="$emit('selectChatType', 'all_chat')" :disabled="chatType === 'all_chat'">
        <img src="./icons/allChat.svg" alt="paperclip" width="30">
        <span v-if="chatType === 'all_chat'" class="badge bg-secondary position-absolute translate-middle" style="left: 85%">{{counter }}</span>
        <small>Tutti</small>
      </button>
    </ul>
    <div></div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.control-bar {
  height: 100%;
  flex-direction: column;
  align-items: center;
  .close-modal>img{
    filter: invert(0.75)
  }
}
/* left 85% */
ul {
  button {
    width: 3.5rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
}

.active {
  background-color: $home_violet;
  border: none;
}

@media (max-width: 991.98px) {
  .control-bar {
    height: auto;
    position: fixed;
    bottom: 0;
    min-width: 100%;
    display: block;
    border-top: 1px solid var(--bs-border-color);
    flex-direction: row;
  }
}
</style>
