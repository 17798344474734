import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchLastDate(prescriptionId) {
  const axios = axiosInstance().primoLab;
  let prescription = [];

  await axios
    .get(`/prescriptions/${prescriptionId}`, {
      params: {
        action: 'with_last_date',
      }
    })
    .then(async (response) => {
      prescription = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescription };
}
