<template>

  <div class="d-flex" :class="{'low-noise': showConfirm}">
    <div class="d-flex flex-column w-75 p-5">

      <template v-if="! allUploaded()">

          <div class="d-flex justify-content-start p-1">
            <Toggle v-model="shouldUploadModel" :disabled="$props.hasUploadedSomeModels" />
            <label class="ms-2">Abilità il caricamento modello</label>
          </div>

        <!-- Models -->
        <template v-if="shouldUploadModel">

          <template v-if="showModelsTitle">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="d-flex">Seleziona i modelli da caricare</h3>
              <!-- Actions -->
              <img id="stump-plus" src="@/assets/icons/model/stump/stump-plus.svg" alt="stump" width="35" @click="addStump()">
              <img id="gum-plus" src="@/assets/icons/model/gum/gum-plus.svg" alt="gum" width="100" @click="addGum()">
              <img id="custom-pin-plus" src="@/assets/icons/model/custom-pin/custom-pin.svg" alt="custom-pin" width="35" @click="addCustomPin()">
            </div>
          </template>

          <div class="d-flex flex-wrap overflow-auto">
            <RenderAssetIcons :elements="$props.elements" @select="selectModel" :showModels="true" @remove="remove"/>
          </div>
        </template>
        <!-- Projects -->
        <h3 v-if="showProjectsTitle" class="d-flex">Seleziona i progetti da caricare</h3>
        <div class="d-flex flex-wrap overflow-auto">
          <RenderAssetIcons :elements="$props.elements" @select="select" :showProjects="true" @remove="remove"/>
        </div>

        <template v-if="(idsToUpload.length > 0 && ! newBridge)">
          <!-- Materials select for project-->
          <div class="input-group mt-4">
            <label class="input-group-text" for="material-selected">
              Materiale predefinito:<span class="fw-bold ms-2 text-uppercase">{{ defaultMaterial }}</span>
            </label>
            <select v-show="showMaterialSelect" id="material-selected" class="form-select" v-model="selectedMaterial.slug">
              <option :value="null">Seleziona il materiale</option>
              <option v-for="(material, index) in allowedMaterials" :value="material.slug" :key="index">
                {{ material.name }}
              </option>
            </select>
          </div>
          <!-- Colors select -->
          <div class="input-group mt-4">
            <select id="color-selected" class="form-select" v-model="selectedColorId">
              <option :value="null">Seleziona il colore</option>
              <option v-for="(color, index) in colors" :value="color.id" :key="index">
                {{ color.code }}
              </option>
            </select>
          </div>
        </template>

        <!-- Drag and Drop -->
        <div class="d-flex mt-5">
          <DragAndDropArea
            :title="'Trascina i file per caricare'"
            :subtitle="'oppure'"
            :bridgeStyle="newBridge"
            :upload="idsToUpload"
            :model="modelToUpload"
            :material="selectedMaterial"
            :colorId="selectedColorId"
            @createBridge="confirmToCreateBridge"
            @assetIdCreated="readAssetIdCreated"
            @resetSelection="resetIdsToUpload"
            @sync-models="syncModels"
          />
        </div>

      </template>

      <!-- Completed template -->
      <template v-else>
        <div class="d-flex justify-content-center flex-column align-items-center h-100 w-100">
          <h3 class="d-flex">Tutti gli elementi sono stati caricati correttamente</h3>
          <div class="p-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" fill="#74C686" class="bi bi-check2-circle" viewBox="0 0 16 16">
              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
            </svg>
          </div>
        </div>
      </template>

    </div>

    <div class="d-flex flex-column w-25 bg-light p-2 align-items-center justify-content-between">
      <FilesUploaded
        :data="$props.elements"
        :allUploaded="allUploaded()"
        @assetsIdsRemoved="readAssetsIdsRemoved"
      />
    </div>

  </div>

  <div v-if="showConfirm" class="d-flex position-absolute top-50 start-50 translate-middle justify-content-center align-items-center flex-column bg-white border shadow border-4 rounded p-4">
    <h4>Il materiale selezionato è diverso da quello prefedefinito.</h4>
    <p>Sei sicuro di volerlo cambiare?</p>
    <div class="modal-footer p-1 border-0 justify-content-center">
      <button type="button" class="btn btn-violet text-uppercase mt-4" @click="showConfirm = false">si</button>
      <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="restoreMaterial()">no</button>
    </div>
  </div>

</template>

<script>
import { toRaw, ref, onMounted, watch, inject, reactive, computed } from 'vue';
import _ from "lodash";
import Toggle from '@vueform/toggle';

import DragAndDropArea from "./DragAndDropArea";
import FilesUploaded from "./FilesUploaded";

import { findIconByPrescriptionRows } from "@/use/repositories/findIconByPrescriptionRows";
import { fetchAll as fetchAllMaterials } from "@/use/repositories/materials/fetchAll";
import { fetchAll as fetchAllColors } from "@/use/repositories/colors/fetchAll";
import RenderAssetIcons from '../RenderAssetIcons.vue';

export default {
  name: "Upload",
  components: {
    FilesUploaded,
    DragAndDropArea,
    Toggle,
    RenderAssetIcons
  },
  props: {
    elements: {
      type: Object,
      required: true
    },
    showProjectsTitle: {
      type: Boolean,
      required: true,
    },
    showModelsTitle: {
      type: Boolean,
      required: true,
    },
    hasUploadedSomeModels: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'removeBridge',
    'createBridge',
    'asset',
    'assetsIdsRemoved',
    'reset',
    'allFilesUploaded',
    'clear-models-selected',
    'sync-models',
    'add-stump',
    'remove-stump',
    'add-gum',
    'remove-gum',
    'add-custom-pin',
    'remove-custom-pin',
    'force-models-upload',
    'select',
    'remove-bridge'
  ],
  setup(props, {emit}) {
    const newBridge = ref(false);
    const prepareIdsForBridge = ref([]);
    const idsBridge = ref([]);
    const idsToUpload = ref([]);
    const allMaterials = ref([]);
    const colors = ref([]);
    const selectedMaterial = reactive({
      id: undefined,
      slug: undefined,
    });
    const defaultMaterial = ref();
    const showMaterialSelect = ref(true);
    const selectedColorId = ref(null);
    const showConfirm = ref(false);
    const modelToUpload = ref([]);
    const isModel = ref(false);
    const prefillMaterial = ref(false);
    const prefillColor = ref(false);
    const { materials } = inject("constants");

    const allowedMaterials = computed(() => {
      return allMaterials.value.filter((material) => {
        return material.slug !== materials.PRINTED_RESIN;
      });
    })

    const shouldUploadModel = ref(props.elements.models.must_upload);

    const handleMaterial = () => {
      if (props.elements.default_materials !== undefined) {
        if (props.elements.default_materials.length === 1) {
          showMaterialSelect.value = false;
          selectedMaterial.id = _.head(props.elements.default_materials).id;
          selectedMaterial.slug = _.head(props.elements.default_materials).slug;
          defaultMaterial.value = _.head(props.elements.default_materials).name;
          return;
        } else if (props.elements.default_materials.length > 1) {
          allMaterials.value = props.elements.default_materials;
        }
      }

      return defaultMaterial.value = _.kebabCase(props.elements.prescription_material);
    }

    onMounted(() => {
      // Get all materials type
      handleMaterial();
      if (! allMaterials.value.length) {
        fetchAllMaterials().then((response) => {
          allMaterials.value = response.materials;
        });
      }

      // Get all colors
      fetchAllColors()
        .then((response) => (colors.value = response.colors));
    });

    watch(() => shouldUploadModel.value, (value) => {
      emit('force-models-upload', value);
    });


    watch(() => selectedMaterial, (value) => {
      // Destruct
      const { slug } = value;

      if (slug !== "resina-stampata") {
        // Pre selection
        if (! showMaterialSelect.value) return;

        // Check default material
        if (slug !== _.kebabCase(props.elements.prescription_material)) {
          // Show confirm window
          showConfirm.value = true;
        }
      }

      // Update selected material id
      const selectedItem = allMaterials.value.find((item) => item.slug === slug);

      if (selectedItem) {
        selectedMaterial.id = selectedItem.id;
      }
    }, {
      deep: true,
    });

    watch(() => idsToUpload.value.length, (value) => {
      if (value === 0) {
        resetAssetParameters();
      }
    });

    const addStump = () => emit("add-stump");

    const addGum = () => emit("add-gum");

    const addCustomPin = () => emit("add-custom-pin");

    const resetMaterial = () => {
      selectedMaterial.id = props.elements.models.material.id;
      selectedMaterial.slug = props.elements.models.material.slug;
    }

    const resetAssetParameters = () => {
      selectedColorId.value = props.elements.prescription_color_id;
      if (! props.elements.default_materials) {
        resetMaterial();
      };
    }

    const prefillAssetParameters = () => {
      selectedColorId.value = props.elements.models.prescription.colorScale.id;
      resetMaterial();
    }

    // Render icons
    const getIcons = (tooth) => {
      // Render only tooth with empty assets
      if (tooth.assets.length > 0) {
        return;
      }

      return findIconByPrescriptionRows(tooth);
    }

    // Read and sent ids to Icon component
    const getIds = (array) => {
      let ids = [];
      array.forEach((el) => {
        ids.push(el.id);
      });
      return ids;
    }

    // Choose icon action.
    const select = (array) => {
      // No cross selected with models
      // Reset models selection if exists
      if (modelToUpload.value.length !== 0) {
        isModel.value = false;
        emit("clear-models-selected");
      }

      // Clear model to upload
      modelToUpload.value = [];

      handleMaterial();

      resetAssetParameters();

      // If elements > 1 is a bridge
      let isBridge = array.length > 1;

      // If elements === 1 is a tooth/arch/sest/emiarch
      let isTooth = array.length === 1;

      if (isBridge) {
        console.log("BRIDGE");
        if (idsToUpload.value.length === 1 ) {
          resetSelection();
        }

        // Sync ids
        array.forEach((el) => {
          // Toggle ids bridge
          let indexIdsBridge = idsBridge.value.findIndex((item) => item.id === el.id);
          let indexIdsToUpload = idsToUpload.value.findIndex((item) => item === el.id);

          if (indexIdsBridge !== -1) {
            idsBridge.value.splice(indexIdsBridge, 1);
            idsToUpload.value.splice(indexIdsToUpload, 1);
            emit('select', el.id);
          } else {
            emit('select', el.id);
            idsBridge.value.push(el);
            idsToUpload.value.push(el.id);
          }
        });
      }

      if (isTooth) {
        if (idsBridge.value.length > 0) {
          resetSelection();
        }

        // Get tooth
        let tooth = _.head(array);

        console.log("IS TOOTH");
        syncPrepareIdsForBridge(tooth);

        // If a tooth is not sequential in the selection of the bridge
        if (prepareIdsForBridge.value.length > 1) {
          if (isPossibleCreateBridge(prepareIdsForBridge.value[prepareIdsForBridge.value.length - 1])) {
            newBridge.value = true;
          } else {
            newBridge.value = false;
            resetSelection();
            syncPrepareIdsForBridge(tooth);
          }
        } else {
          newBridge.value = false;
        }

        syncIds(idsToUpload, tooth.id);
      }
    }

    watch(() => isModel.value, (value) => {
      if (value) {
        // Prefill asset info
        prefillAssetParameters();
      }
    });

    const selectModel = (model) => {
      let selectionRemember = model.selected;

      // Reset all models selected for toggle effect
      emit("clear-models-selected");

      // Reset tooth and bridge selected if exists
      if (idsToUpload.value.length !== 0) {
        resetSelection();
      }

      if (selectionRemember === false) {
        // Prefill and disabled material and color
        prefillMaterial.value = true;
        prefillColor.value = true;
        // Model is selected
        isModel.value = true;
        model.selected = true;
        // Clear model to upload
        modelToUpload.value = [];
        // Prepare model to upload
        modelToUpload.value.push(model);
      }

      if (selectionRemember) {
        model.selected = false;
        // Clear and enabled material and color
        prefillMaterial.value = false;
        prefillColor.value = false;
        // Model not selected
        isModel.value = false;
        // Clear model to upload
        modelToUpload.value = [];
      }
    }

    const syncIds = (array, id) => {
      if (array.value.includes(id)) {
        // Remove tooth ids
        array.value.splice(array.value.indexOf(id), 1);
      } else {
        // Add tooth ids
        array.value.push(id);
      }
    }

    const syncPrepareIdsForBridge = (obj) => {
      // Sync ids tooth
      let index = prepareIdsForBridge.value.findIndex((el) => el.id === obj.id);
      if (index !== -1) {
        obj.selected = false;
        // Remove obj
        prepareIdsForBridge.value.splice(index, 1);
      } else {
        obj.selected = true;
        // Add obj
        prepareIdsForBridge.value.push(obj);
      }
    }

    // Check if possible create a bridge
    const isPossibleCreateBridge = (currentTooth) => {
      // If the last tooth is not sequential
      let lastZoneNumber = parseInt(
        prepareIdsForBridge.value[prepareIdsForBridge.value.length - 2]?.zone_number
      );
      let currentZoneNumber = parseInt(currentTooth.zone_number);

      // Create array with only zone number
      let zoneNumbers = [];
      prepareIdsForBridge.value.forEach((el) => {
        zoneNumbers.push(parseInt(el.zone_number));
      });

      // If the current value is below the threshold
      if (
        (currentZoneNumber === 11 && lastZoneNumber === 21) ||
        (currentZoneNumber === 21 && lastZoneNumber === 11)
      ) {
        return true;
      }

      // If the current value is above the threshold
      if (
        (currentZoneNumber === 31 && lastZoneNumber === 41) ||
        (currentZoneNumber === 41 && lastZoneNumber === 31)
      ) {
        return true;
      }
      // Convert negative value to positive
      return Math.abs(lastZoneNumber - currentZoneNumber) == 1;
    }

    // Read to user choose bridge's action
    const confirmToCreateBridge = (value) => {
      if (value) {
        // Create
        let ids = [];
        prepareIdsForBridge.value.forEach((obj) => ids.push(obj.id));
        emit('createBridge', ids);
      }

      // Remove selected style
      resetSelection();
    }

    // Checks if all working teeth
    const allUploaded = () => {
      let allUploaded = true;

      props.elements.bridges.forEach((el) => {
        if (el.tooth[0].assets.length === 0) {
          allUploaded = false;
        }
      });

      props.elements.tooth.forEach((el) => {
        if (el.assets.length === 0) {
          allUploaded = false;
        }
      });

      if (props.elements.models.must_upload) {
        props.elements.models.arches.forEach((el) => {
          if (el.assets.length === 0) {
            allUploaded = false;
          }
        });
        props.elements.models.gums.forEach((el) => {
          if (el.assets.length === 0) {
            allUploaded = false;
          }
        });
        props.elements.models.stumps.forEach((el) => {
          if (el.assets.length === 0) {
            allUploaded = false;
          }
        });
      }

      emit('allFilesUploaded', allUploaded);

      return allUploaded;
    }

    const resetSelection = () => {
      prepareIdsForBridge.value = [];
      idsToUpload.value = [];
      idsBridge.value = [];
      newBridge.value = false;
      emit('reset');
    }

    const readAssetIdCreated = (obj) => {
      emit('asset', obj);
      resetSelection();
      modelToUpload.value = [];
    }

    const readAssetsIdsRemoved = (ids) => {
      emit('assetsIdsRemoved', ids);
      resetSelection();
    }

    const resetIdsToUpload = (value) => {
      idsToUpload.value = value;
      if (! props.elements.default_materials.length) {
        resetMaterial();
      }
      selectedColorId.value = props.elements.prescription_color_id;
    }

    const restoreMaterial = () => {
      if (! props.elements.default_materials.length) {
        resetMaterial();
      }

      selectedColorId.value = props.elements.prescription_color_id;
      showConfirm.value = false;
    }

    const syncModels = (obj) => {
      emit("sync-models", obj);
      isModel.value = false;
      emit("clear-models-selected");
    }

    const remove = (event) => {
      resetSelection();
      return emit(event.event_type, event?.data?.value)
    }

    return {
      getIcons,
      select,
      toRaw,
      newBridge,
      confirmToCreateBridge,
      prepareIdsForBridge,
      getIds,
      idsToUpload,
      idsBridge,
      readAssetIdCreated,
      readAssetsIdsRemoved,
      resetIdsToUpload,
      allUploaded,
      colors,
      selectedMaterial,
      defaultMaterial,
      selectedColorId,
      showConfirm,
      restoreMaterial,
      modelToUpload,
      selectModel,
      prefillMaterial,
      prefillColor,
      syncModels,
      addStump,
      addCustomPin,
      addGum,
      shouldUploadModel,
      showMaterialSelect,
      allowedMaterials,
      remove
    }
  }
}
</script>

<style scoped>
 #stump-plus:hover,
 #gum-plus:hover,
 #custom-pin-plus:hover {
   cursor: pointer;
 }
</style>
