<script>
import ChatHeader from '@/components/primoChat/ChatHeader.vue';
import ChatBody from '@/components/primoChat/ChatBody.vue';
import Modal from '@/components/Modal/Standard';
import ExternalAttachment from '@/components/primoChat/ExternalAttachment';

import { inject, computed, nextTick, reactive, ref, watch, } from 'vue';

import store from "@/store";
import moment from "moment/moment";
import _ from "lodash";

import { show as showMessage } from "@/use/repositories/chat/message/show";
import { fetchAll as fetchAllMessages } from "@/use/repositories/chat/message/fetchAll";
import { store as storeMessageChat } from "@/use/repositories/chat/message/store";
import { assignUrl } from "@/use/repositories/chat/media/assignUrl";
import { formatFilename } from "@/use/utilities/media/formatFilename";
import { hideModal as closeModal } from "@/use/modal/hide";

import { api as viewerApi } from "v-viewer";

export default {
  emits: ['toFavorite'],
  setup() {
    const bodyChat = ref(null);
    const mobileLayout = inject("mobileLayout");
    const prescription = inject("prescription");
    const pusherEvent = inject("pusherEvent");
    const renderChatBodyKey = ref(0)

    const canUseChat = ref(true);
    const chatChannelId = ref(null);

    const message = ref("");
    const media = ref("");
    const fileNameToUpload = ref("");
    const sendingMessage = ref(false);
    const mediaToUpload = ref("");

    const messages = ref([]);
    const page = ref(1);
    const totalPages = ref(1);
    const isHistoryMessages = ref(false);
    const existsHistory = computed(() => {
      return (page.value < totalPages.value && totalPages.value > 1);
    });


    const loading = ref(true);
    const conversation = ref(null);
    const onTheBottom = ref(null);
    const badgeNewMessageArrived = ref(false);
    const messageArrivedIsMine = ref(true);
    const debug = ref(false);
    const haveScroll = ref(null);
    const conversationFooter = ref(null);
    const conversationContainer = ref(null);
    const messageInput = ref(null);
    const qrData = reactive({
      files: [],
    });
    const showQrcodeModal = ref(false);

    const closeQrcodeModal = async () => {
      await closeModal('qrcode');
      showQrcodeModal.value = false;
    }

    const handlePusherEvent = (event) => {
        switch (event.eventName) {
          case "pusher:subscription_succeeded":
            console.log("subscription succeeded");
            break;
          case "messaging":
            messageArrivedIsMine.value = (event.data.user_id === store.state.user.id);
            if (chatChannelId.value === event.data.chat_channel_id) {
              isHistoryMessages.value = false;
              findMessageById(event.data.id);
            }
          break;
        }

        console.log(`bind global channel: The event ${event.eventName} was triggered with data ${JSON.stringify(event.data)}`);
    }


    const updateComponent = () => {
      enabledInputMessage();
      renderChatBodyKey.value = renderChatBodyKey.value + 1;
    }

    const handleChatChannel = () => {
      if (isValidCHannel()) {
        console.log("CHANNEL EXISTS");
        chatChannelId.value = prescription.chat_channel.id;
        console.log(`PRESCRIPTION CHANNEL ID: ${chatChannelId.value}`);
        getMessages(chatChannelId.value, false, true)
      } else {
        console.log("CHANNEL NOT EXISTS");
        chatChannelId.value = null;
        loading.value = false;
      }
    }

    const syncFiles = async (files) => {
      files.forEach((mediaName) => {
        qrData.files.push(mediaName);
      });

      await storeMessage();

      if (qrData.files.length && chatChannelId.value != null) {
        const formData = createFormData();
        formData.set("action", "with_media");

        qrData.files.forEach((mediaName) => {
          formData.append("file_names[]", mediaName);
        });

        storeMessageChat(formData);
        clearMessageField();
        bodyChat.value.scrollToBottom();
        closeQrcodeModal();
        qrData.files = [];
      }
    }

    const getMessages = async (id, history = false, getMore = false) => {
      fetchAllMessages({
        action: "by_channel",
        channel_id: id,
        page: page.value,
      }, true).then((response) => {
        if (history) {
          response.data.data.forEach((message) => {
            syncMessage(message, history);
          });
        } else {
          messages.value = _(response.data.data)
            .map((value) => {
              let msg = transformDataMessage(value);
              return {
                ...msg,
                date: moment(value.created_at).format("DD/MM/YYYY"),
              };
            })
            .groupBy('date')
            .map((value, key) => ({
              date: key, messages: _.chain(value)
                .sortBy('created_at')
                .value()
                .reverse()
            }))
            .value()
            .reverse();
        }
        // Hide spinner.
        loading.value = false;
        // Set total pages.
        totalPages.value = response.data.meta.last_page;

        // Request more messages for the first interaction
        if(getMore) getHistory();
      });
    };

    const getMessageType = (message) => {
      if (message.channel_message_type === 'warning') {
        return;
      }
      return message.channel_message_type;
    }

    const transformDataMessage = (message) => {
      return {
        first_name: message.user.first_name,
        last_name: message.user.last_name,
        channel_message_type: message.message_type.slug,
        channel_message_color: message.message_type.color,
        is_sender: (store.state.user.id === message.user_id),
        time: moment(message.created_at).format("HH:mm"),
        text: renderMessage(message.body),
        attachments: getThumbnails(message),
        role: message?.user_role?.name,
      }
    }

    const renderMessage = (string) => {
      return string.replace(String.fromCharCode(92), String.fromCharCode('', ''));
    }

    const findMessageById = (id) => {
      showMessage(id).then((response) => {
        syncMessage(response.message);
      });
    }

    const syncMessage = (data, history = false) => {
      let createdAt = moment(data.created_at).format("DD/MM/YYYY");
      let formattedMessage = transformDataMessage(data);
      // Find date and push.
      let index = messages.value.findIndex(item => {
        return item.date === createdAt;
      });
      if (index === -1) {
        let data = {
          date: createdAt,
          messages: [formattedMessage],
        };
        // If the date doesn't exist create the group and push message.
        if (history) {
          messages.value.unshift(data);
        } else {
          messages.value.push(data);
        }
      } else {
        // If date exist push message.
        if (history) {
          messages.value[index].messages.unshift(formattedMessage);
        } else {
          messages.value[index].messages.push(formattedMessage);
        }
      }
      // Hide loader.
      loading.value = false;
      enabledInputMessage();
    }

    const getPositionMessage = (message) => {
      return (message.is_sender ? 'end' : 'start');
    };

    const getThumbnails = (message) => {
      let urls = [];
      message.media.forEach((singleMedia) => {
        urls.push(singleMedia.temporary_url);
      })
      return urls;
    };

    const attach = () => {
      fileNameToUpload.value = media.value.files[0].name;
      mediaToUpload.value = media.value.files[0];
    };

    const storeMessage = async () => {
      // Check if exists data to store.
      if (
        (message.value.trim().length === 0 &&
          typeof mediaToUpload.value !== 'object' &&
          qrData.files.length === 0)
      ) {
        return;
      }
      // Enabled loader.
      loading.value = true;
      disabledInputMessage();
      let formData;
      if (chatChannelId.value === null) {
        console.warn("Chat doesnt exists...");
      } else {
        bodyChat.value.scrollToBottom();
        formData = createFormData();
        console.log('formData', formData);
        save(formData);
      }
    }

    const save = (formData) => {
      // Store with media.
      if (typeof mediaToUpload.value === 'object') {
        let fileParsed = formatFilename(mediaToUpload.value);
        assignUrl(fileParsed).then(async (response) => {
          // Get signed url.
          let inputs = response.assignUrl.inputs;
          let attributes = response.assignUrl.attributes;

          let formDataForS3 = new FormData();
          Object.keys(inputs).forEach((key) => {
            formDataForS3.append(key, inputs[key]);
          });
          formDataForS3.append('file', mediaToUpload.value);

          // Upload media to S3.
          await fetch(attributes.action, {
            method: "POST",
            body: formDataForS3,
            mode: "cors",
          }).then(async () => {
            formData.append("file_names[]", fileParsed);
            // Store without media.
            storeMessageChat(formData).then(() => {
              console.log("store message");
              clearMessageField();
              clearAttachment()
              bodyChat.value.scrollToBottom();
            });
          }).catch((error) => {
            console.log(error);
            console.log("S3 media upload failed");
          });
        });

        return;
      }

      // Store message.
      if (message.value.trim().length !== 0) {
        // Store without media.
        storeMessageChat(formData).then(() => {
          console.log("store message");
          clearMessageField();
          bodyChat.value.scrollToBottom();
        });
      }
    }

    const setFocusOnInputMessage = () => {
      nextTick(() => {
        if (messageInput.value) {
          messageInput.value.focus();
        }
      });
    }

    const clearMessageField = () => {
      message.value = "";
    }

    const enabledInputMessage = () => {
      sendingMessage.value = false;
      setFocusOnInputMessage();
    }

    const disabledInputMessage = () => {
      sendingMessage.value = true;
    }

    const clearAttachment = () => {
      mediaToUpload.value = "";
      fileNameToUpload.value = "";
    }

    const createFormData = () => {
      let formData = new FormData();
      formData.append("chat_channel_id", chatChannelId.value);
      formData.append("type_slug", "warning");
      formData.append("message_subcategory_slug", "free-message");
      if (message.value !== "") {
        formData.append("body", message.value);
      }
      if (typeof mediaToUpload.value === 'object') {
        formData.append("action", "with_media");
      } else {
        formData.append("action", "without_media");
      }
      // Display the key/value pairs
      for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      return formData;
    }

    const getHistory = () => {
      if (existsHistory.value) {
        isHistoryMessages.value = true;
        page.value++;
        // Store current scroll height for later restore.
        //let initialHeight = value;
        getMessages(chatChannelId.value, true);
      }
    }

    const previewImage = (message, index) => {
      viewerApi({
        images: message.attachments.map((url) => {
          return {
            "data-source": url,
          }
        }),
        options: {
          toolbar: true,
          url: "data-source",
          initialViewIndex: index,
        },
      });
    }

    const isValidCHannel = () => typeof prescription.chat_channel === "object" && prescription.chat_channel !== null && prescription.chat_channel.id;

    watch(() => pusherEvent, newEvent => handlePusherEvent(newEvent), { deep: true });

    watch(() => prescription.id, () => {
      page.value = 1;
      updateComponent();
      // Verify chat channel and fetch messages.
      handleChatChannel();
    });

    watch(() => messages.value, () => {
      if (onTheBottom.value) {
        bodyChat.value.scrollToBottom();
      } else {
        if (messageArrivedIsMine.value && !isHistoryMessages.value) {
          // Message arrived is mine and scroll to bottom.
          bodyChat.value.scrollToBottom();
        } else if (!isHistoryMessages.value) {
          // Message arrived is not mine and show notify.
          badgeNewMessageArrived.value = true;
        }
      }
    }, { deep: true });

    return {
      mobileLayout,
      prescription,
      getPositionMessage,
      getThumbnails,
      attach,
      message,
      messages,
      //scrollToBottom,
      loading,
      media,
      storeMessage,
      fileNameToUpload,
      conversation,
      totalPages,
      onTheBottom,
      badgeNewMessageArrived,
      page,
      debug,
      haveScroll,
      getHistory,
      getMessageType,
      previewImage,
      unescape,
      sendingMessage,
      messageInput,
      conversationFooter,
      conversationContainer,
      showQrcodeModal,
      closeQrcodeModal,
      chatChannelId,
      canUseChat,
      renderChatBodyKey,
      bodyChat,
      syncFiles,
    };
  },
  components: {
    ChatHeader,
    ChatBody,
    Modal,
    ExternalAttachment
  }
}
</script>
<template>
  <div class="collapse collapse-horizontal multi-collapse h-100 w-100"
    :class="{ 'show': !mobileLayout, 'w-100': mobileLayout }" id="multiCollapseExample2">
    <div class="d-flex flex-column flex-grow-1" ref="conversationContainer" :class="{ 'mobile': mobileLayout, 'h-100': !mobileLayout }" >
      <ChatHeader @to-favorite="(value) => $emit('toFavorite', value)" />
      <ChatBody :chat="messages" ref="bodyChat" :key="renderChatBodyKey" @get-history="getHistory" :spinner="loading" />
      <!-- <ChatFooter /> -->
      <template v-if="fileNameToUpload !== ''">
        <small class="bg-green text-white p-2 position-absolute" style="bottom: 60px;">{{ fileNameToUpload }}</small>
      </template>
      <div ref="conversationFooter" class="conversation__footer w-100">
        <div class="conversation__footer__actions w-100 h-100 d-flex justify-content-evenly align-items-center" v-if="prescription.id">
          <input type="file" ref="media" class="d-none" @change="attach()">
          <template v-if="! mobileLayout">
            <button class="btn" :disabled="!prescription.id">
            <img src="../../assets/icons/chat/qr-code.svg" alt="qr-code" width="30" @click="showQrcodeModal = true;">
          </button>
          </template>
          <button class="btn" @click="$refs.media.click()" :disabled="!prescription.id">
            <img src="./icons/paperClip.svg" alt="paperclip" width="30">
          </button>
          <!-- <textarea type="text" autocomplete="off" class="form-control w-100" placeholder="Scrivi un messaggio" /> -->
          <textarea rref="messageInput" :disabled="!prescription.id || !canUseChat || sendingMessage" onkeydown="return (event.keyCode!=13);"
            type="text" autocomplete="off" class="form-control" placeholder="Scrivi un messaggio" v-model="message"
          />
          <button class="btn" :disabled="!prescription.id || sendingMessage || (message === '' && media === '')"
            :class="{ 'opacity': (message === '' && media === '') }" @click="storeMessage()">
            <img class="conversation__footer__input__icon__send" src="./icons/sendFill.svg" alt="send" width="30">
          </button>
        </div>
      </div>
    </div>
  </div>
  <template v-if="! mobileLayout">
    <Modal v-if="showQrcodeModal" class="d-flex" :size="'md'" :modal_id="'qrcode'" :title="'Scansiona il QR'"
    :subtitle="'Scansionando il QR potrai caricare i files direttamente dal cellulare'" :decline_label="'chiudi'"
    :lightLayout="true" @hidden="closeQrcodeModal">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <ExternalAttachment @sync-files="syncFiles"/>
    </div>
  </Modal>
  </template>
</template>
<style scoped>
.w-fit-content {
  width: fit-content;
}
textarea {
  resize: none;
  height: 1rem;
  max-height: 5rem;
}

.conversation__footer {
  padding: 0.5rem;
  background-color: #f5f5f5;
}

.conversation__footer__actions > input {
  margin: 0 10px;
  border-radius: 10px;
}

.mobile {
  height: calc(100%);
}

textarea {
  resize: none;
  height: 5px;
  max-height: 70px;
}

@media (max-width: 991.98px) {
  .conversation__footer {
    padding: 1rem 0.5rem 3rem 0.5rem;
    z-index: 1;
  }
}
</style>
