<script>
import {api as viewerApi} from "v-viewer";
import { computed } from "vue";
export default {
  name: 'ChatMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const previewImage = (message, index) => {
      viewerApi({
        images: message.attachments.map((url) => {
          return {
            "data-source": url,
          }
        }),
        options: {
          toolbar: true,
          url: "data-source",
          initialViewIndex: index,
        },
      });
    }

    const parsedMessageBody = computed(() => (props.message.text.replace(/\\"(?=\?)/g, '"')));

    return { previewImage, parsedMessageBody }
  }
}
</script>
<template>
  <div class="conversation__message shadow d-flex flex-column w-70"
    :class="{ 
      'align-self-end': $props.message.is_sender,
      'text-light': $props.message.channel_message_type !== 'default'
    }"
    :style="{ 'backgroundColor': $props.message.channel_message_color }"
    >
    <!-- Message -->
    <div class="conversation__message__user fw-bold mb-2">
      {{ $props.message.first_name }} {{ $props.message.last_name }}
      <span class="badge badge-pill bg-dark-violet ms-1">{{ $props.message.role }}</span>
    </div>
    <div class="conversation__message__text"> {{ parsedMessageBody }}</div>
    <template v-if="$props.message.attachments.length">
      <template v-for="(url, index) in $props.message.attachments" :key="index">
        <img @click="previewImage(message, index)" class="media mb-2" width="100" :src="url" alt="img">
      </template>
    </template>
    <div class="conversation__message__time text-end">{{ $props.message.time }}</div>
  </div>
</template>
<style scoped lang="scss">
.conversation__message {
  border-radius: 10px;
  margin: 1rem 1.5rem;
  padding: .5rem;
  width: fit-content;
  max-width: 30rem;
  white-space: pre-line;
}
.info {
  background-color: #138496!important;
  color: white!important;
}
.warning {
  background-color: #DD7230 !important;
  color: white !important;
}
.alert {
  background-color: rgb(233, 123, 134) !important;
  color: white !important;
}
.conversation__message__user {
  display: flex;
  align-items: center;
}

.conversation__message__user,
.conversation__message__time {
  font-size: 0.8rem;
}

.conversation__message__text,
.conversation__message__date {
  font-size: 1rem;
}</style>
