<template>
  <div class="d-flex">
    <template v-if="shippingDays !== null">
      <strong class="fs-3">
        {{ shippingDays }}g
        <img src="@/assets/icons/clock-black.svg" width="40" height="40" alt="clock-black">
      </strong>
    </template>
    <template v-if="loading">
      <Spinner />
    </template>
  </div>
</template>

<script>
import moment from "moment";
import {calcWeekDays} from "@/use/utilities/time/calcWeekDays";
import {onMounted, ref} from "vue";
import {fetchLastDate} from "@/use/repositories/prescriptions/fetchLastDate";
import Spinner from "@/components/general/Spinner.vue";
import _head from "lodash/head";

export default {
  name: "LastDate",
  props: {
    prescriptionId: {
      type: Number,
      required: true
    }
  },
  components: {
    Spinner
  },
  setup(props) {
    const shippingDays = ref(null);
    const loading = ref(false);
    const getShippingDays = (date) => {
      let diffDays = moment(moment()).diff(date, 'days');
      let weekDays = calcWeekDays(date, moment());

      return (diffDays - weekDays);
    }

    onMounted(() => {
      loading.value = true;
      fetchLastDate(props.prescriptionId).then((response) => {
        if (response.prescription.dates.length === 0) return;
        console.log(`LAST DATE AVAILABLE: ${_head(response.prescription.dates).start_at}`);
        let formattedDate = moment(_head(response.prescription.dates).start_at, "YYYY-MM-DD HH:mm:ss");
        shippingDays.value = getShippingDays(formattedDate);
        console.log(shippingDays.value);
      }).finally(() => {
        loading.value = false;
      });
    });

    return {
      getShippingDays,
      shippingDays,
      loading
    }
  }
}
</script>
