<template>
  <div class="dashboard container-fluid py-3 vh-100 vw-100">
    <!-- Header -->
    <div class="row">
      <!-- Header for CAM department -->
      <template v-if="isCurrentDepartment(departments.CAM) && testWorkQueuePhase.data === null">
        <div class="w-100">
          <div class="d-flex flex-column align-items-center px-2 h-100 position-relative">
            <!-- Logo -->
            <Brand :width="180" :withChat="testWorkQueuePhase.data !== null" />
            <!-- Redirect -->
            <div class="position-absolute top-0 start-0 p-2" @click="$router.go(-1)">
              <button class="btn rounded-btn-custom shadow bg-violet text-white">
                <div class="d-flex align-items-center">
                  <img src="@/assets/icons/arrow-left.svg" width="30" alt="arrow-left">
                  <strong class="text-uppercase">Indietro</strong>
                </div>
              </button>
            </div>
          </div>
        </div>
      </template>

      <!-- Normal header -->
      <template v-if="testWorkQueuePhase.data !== null">
        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-2">
          <div class="d-flex flex-column justify-content-between align-items-start px-2 h-100">
            <!-- Logo -->
            <Brand :width="180" :withChat="testWorkQueuePhase.data !== null" />
            <!-- Redirect -->
            <div class="d-flex align-items-center" @click="$router.go(-1)" v-if="dashboardReadonly && showWareHouse === false">
              <button class="btn rounded-btn-custom shadow bg-violet text-white">
                <div class="d-flex align-items-center">
                  <img src="@/assets/icons/arrow-left.svg" width="30" alt="arrow-left">
                  <strong class="text-uppercase">Indietro</strong>
                </div>
              </button>
            </div>
            <!-- Manager -->
            <template v-if="hasRole('manager')">
              <MainButton
                class="my-2"
                :showBell="dataManagerCount > 0"
                :showChevronRight="true"
              />
            </template>
            <!-- Warehouse -->
            <template v-if="! isCurrentDepartment(departments.PROGETTAZIONE)">
              <!-- Order material -->
              <div class="d-flex align-items-center position-relative" @click="showWareHouse = true" v-if="showWareHouse === false">
                <button class="btn rounded-btn-custom shadow bg-violet text-white">
                  <div class="d-flex align-items-center">
                    <strong class="text-uppercase">magazzino</strong>
                    <img src="@/assets/icons/shopping-cart.svg" width="30" alt="shopping-cart">
                  </div>
                </button>
              </div>
              <!-- Comeback -->
              <div class="d-flex align-items-center" @click="showWareHouse = false" v-else>
                <button class="btn rounded-btn-custom shadow bg-violet text-white">
                  <div class="d-flex align-items-center">
                    <img src="@/assets/icons/arrow-left.svg" width="30" alt="arrow-left">
                    <strong class="text-uppercase">Indietro</strong>
                  </div>
                </button>
              </div>
            </template>
          </div>
        </div>
      </template>

      <template v-if="!isCurrentDepartment(departments.CAM) && testWorkQueuePhase.data !== null">
        <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-10">
          <!-- Prescription details -->
          <div class="d-flex card shadow rounded-custom justify-content-center p-2 h-100 w-100">
            <div class="d-flex p-2">
              <!-- Note -->
              <div class="d-flex p-2 flex-column" style="width: 60%;">
                <strong class="mb-3">
                  {{  isRefactor ? 'Rifacimento' : 'Prescrizione' }}
                  n.{{ prescription.number_text }}
                  -
                  Creata il {{ moment(prescription.created_at).format("DD/MM/YYYY HH.mm") }}
                  <template v-if="showNotesIcon">
                    <NotesIcon id="notesIcon" color="#725D82" class="ms-2" @click="() => showAllNotesModal = true" />
                  </template>
                </strong>
                <small class="text-uppercase fw-bold">
                  {{ testWorkQueuePhase.data.test_work_queue.prescription_test.name }}
                </small>
                <em class="mb-3">
                  {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription?.prescription_rows[0]?.name }}
                </em>
                <p class="mb-3">
                  {{ testWorkQueuePhase.data.test_work_queue.prescription_test.notes ?? "Nessuna nota presente" }}
                  <small class="text-muted fst-italic" v-if="testWorkQueuePhase.data.test_work_queue.prescription_test.notes !== null">
                    -
                    {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.doctor.first_name }}
                    {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.doctor.last_name }}
                  </small>
                </p>
              </div>
              <!-- Vertical line -->
              <div class="vertical-line m-2"></div>
              <!-- Icons -->
              <div class="d-flex p-2 justify-content-evenly align-items-center" style="width: 60%;">
                <div class="d-flex flex-column text-center align-items-center">
                  <!-- Customer history -->
                  <template v-if="previousAssets.length > 0 && isCurrentDepartment(departments.PROGETTAZIONE)">
                    <div id="customer">
                      <div id="icon-box" @click="historyShowModal = true">
                        <img src="@/assets/icons/box.svg" height="40" alt="box">
                      </div>
                      <div id="blob">
                        <img src="@/assets/icons/blob.svg" height="170" alt="blob">
                      </div>
                      <div id="history-customer">
                        <img src="@/assets/icons/paziente.svg" width="35" height="35" alt="customer">
                        <div class="d-flex pt-2">
                          <small>
                            {{ prescription.customer.first_name }}
                            {{ prescription.customer.last_name }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <img src="@/assets/icons/paziente.svg" width="35" height="35" alt="customer">
                    <div class="d-flex pt-2">
                      <small>
                        {{ prescription.customer.first_name }}
                        {{ prescription.customer.last_name }}
                      </small>
                    </div>
                  </template>
                </div>
                <div class="d-flex flex-column text-center align-items-center">
                  <img src="@/assets/icons/poltrona_dentista.svg" width="35" height="35" alt="dentistchair">
                  <div class="d-flex pt-2">
                    <small>
                      {{testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.clinic.name }}
                      <a class="link-dark" :href="'tel:' + testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.clinic.phone">
                        {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.clinic.phone }}
                      </a>
                    </small>
                  </div>
                </div>
                <div class="d-flex flex-column text-center align-items-center">
                  <img src="@/assets/icons/dottore.svg" width="35" height="35" alt="doctor">
                  <div class="d-flex flex-column pt-2">
                    <small>
                      {{testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.doctor.first_name }}
                      {{testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.doctor.last_name }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <Confirm
        v-if="showPartialCompletionModal && isCurrentDepartment(departments.PROGETTAZIONE)"
        :modal_id="'modalConfirmPartialCompletion'"
        :title="'Conferma conclusione lavorazione'"
        :subtitle="'Non hai caricato tutti i progetti. Sei sicuro/a di voler procedere?'"
        :confirm_label="'Si'"
        :decline_label="'No'"
        @hidden="hidePartialCompletionModal"
        @confirm="completeInternalPhase"
        @decline="hidePartialCompletionModal">
      </Confirm>

    </div>

    <!-- Dashboard -->
    <template v-if="showWareHouse === false && testWorkQueuePhase.data !== null">
      <div class="row h-75">
        <div class="current-work h-100 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3">
          <!-- Info current work -->
          <div class="card shadow rounded-custom overflow-auto h-100">

            <div class="card-header rounded-only-top bg-dark-violet text-center text-white text-uppercase m-0"
              :class="{'refactor': isRefactor}">
              <!-- Dynamic title by departments -->
              <template v-if="isCurrentDepartment(departments.PROGETTAZIONE)">progettazione</template>
              <template v-else>{{ testWorkQueuePhase.data.internal_phase.name }}</template>
            </div>

            <div class="card-body rounded-custom overflow-auto">
              <!-- Caution note LAB -->
              <template v-if="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.caution &&
                testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.caution !== ''">
                <div class="fw-bold">Note Prescrizione</div>
                <div class="mb-3">
                  {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.caution }}
                </div>
              </template>
              <!-- Internal note LAB -->
              <div class="fw-bold">Note interne LAB</div>
              <template v-if="testWorkQueuePhase.data.parent_test_work_queue_phases.length > 0">
                <div class="mb-3"></div>
              </template>
              <template v-else>
                <p class="mb-3">Nessuna nota interna presente</p>
              </template>

              <!-- Internal note history -->
              <div class="mb-3">
                <small class="mb-3">
                  <a href="#" class="text-violet text-decoration-underline"
                     @click="showHistoryInternalNotes = true">
                    Vedi tutte le note interne
                  </a>
                </small>
              </div>

              <hr />

              <!-- Works details -->
              <div class="fw-bold mb-2">Dettagli lavorazione</div>
              <div class="d-flex">
                <div>
                  <ul style="padding-left: 1.2rem;">
                    <li class="pb-1 fw-bold"><small>Materiale: {{testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.material }}</small></li>
                    <li class="pb-1 fw-bold"><small>Tipo impianto: {{testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.plant_type }}</small></li>
                  </ul>
                </div>
                <div>
                  <ul style="padding-left: 3rem;">
                    <li class="pb-1 fw-bold"><small>Bruxista: {{ prescription.customer.bruxism ? "SI" : "NO" }}</small></li>
                    <li class="pb-1 fw-bold"><small>Colore: {{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription?.color?.code }}</small></li>
                  </ul>
                </div>
              </div>

              <hr />

              <Elements
                :is-able-to-project="isCurrentDepartment(departments.PROGETTAZIONE)"
                :model-types="modelTypes"
                :elements="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription?.prescription_rows"
                :models="models"
                :prescription-material="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.material"
                :prescription-color-id="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.color.id"
                :default-materials="testWorkQueuePhase.data?.test?.materials"
                @allFilesIsUploaded="readAllFilesIsUploaded"
                @all-models-is-uploaded="readAllModelsIsUploaded"
                @atLeastOneFileIsUploaded="readAtLeastOneFileIsUploaded"
                @clear-models-selected="clearModelSelected"
                @sync-models="syncModelsData"
                @remove-assets-models="removeAssetsModel"

                @add-stump="addStump"
                @remove-stump="removeStump"

                @add-gum="addGum"
                @remove-gum="removeGum"

                @add-custom-pin="addCustomPin"
                @remove-custom-pin="removeCustomPin"
                @force-models-upload="forceModelsHandle"
              />

              <hr>

              <template v-if="prescription.characterizations.length">
                <!-- Characterization -->
                <ul style="padding-left: 1.2rem;">
                  <li class="pb-3"><strong>Caratterizzazione:</strong></li>
                </ul>
                <div>
                  <Slider :teeth_characterization="prescription.characterizations" />
                </div>
              </template>
              <template v-else>
                <div class="mt-3">
                  Nessuna caratterizzazione presente.
                </div>
              </template>

            </div>

            <!-- Actions -->
            <div class="card-footer bg-dark-violet rounded-only-bottom d-flex position-absolute bottom-0 w-100 overflow-auto" :class="{disabled: dashboardReadonly, 'refactor': isRefactor}">
              <div class="d-flex p-0">

                <!-- Situ -->
                <template v-if="showSITU && Object.keys(testWorkQueuePhaseChild).length > 0">
                  <div class="d-flex align-items-center ms-2">
                    <Situ
                      :testWorkQueuePhaseId="testWorkQueuePhase.data.id"
                      :testWorkQueuePhaseChildId="testWorkQueuePhaseChild.id"
                    />
                  </div>
                </template>

                <!-- External -->
                <template v-if="isCurrentDepartment(departments.PROGETTAZIONE) || isCurrentDepartment(departments.MOBILE)">
                  <div class="d-flex align-items-center ms-2">
                    <button class="btn btn-outline-violet rounded-btn-custom bg-white rounded-circle p-2" @click="requestExternal()">
                      <img src="@/assets/icons/van.svg" width="30" alt="van" />
                    </button>
                  </div>
                </template>

                <template v-if="canSuspend">
                  <!-- Suspend -->
                  <div class="d-flex align-items-center ms-2">
                    <button class="btn btn-outline-violet rounded-btn-custom bg-white rounded-circle p-2" @click="requestSpecialSuspend()">
                      <img src="@/assets/icons/pause.svg" width="30" alt="pause">
                    </button>
                  </div>
                </template>

                <!-- Remember notes -->
                <div class="d-flex align-items-center ms-2">
                  <button class="btn btn-outline-violet rounded-btn-custom bg-white rounded-circle p-2" style="padding: 10px;" @click="rememberNote()">
                    <img src="@/assets/icons/note.svg" width="30" alt="note">
                  </button>
                </div>

                <!-- Info -->
                <div class="d-flex align-items-center ms-2">
                  <button class="btn btn-outline-violet rounded-btn-custom bg-white rounded-circle p-0" @click="managerRequest('info')">
                    <img src="@/assets/icons/special/question.svg" width="45" alt="question" />
                  </button>
                </div>

                <!-- Help -->
                <div class="d-flex align-items-center ms-2">
                  <button class="btn btn-outline-violet rounded-btn-custom bg-white rounded-circle p-0" @click="managerRequest('warning')">
                    <img src="@/assets/icons/special/exclamation-circle.svg" width="45" alt="help" />
                  </button>
                </div>

                <!-- Terminate -->
                <div class="d-flex align-items-center ms-2">
                  <button class="btn btn-outline-violet rounded-btn-custom bg-white" @click="toFinish()">
                    <div class="d-flex align-items-center">
                      <div style="width: 30px; height: 30px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-check-lg"
                          viewBox="0 0 16 16">
                          <path
                            d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                        </svg>
                      </div>
                      <strong>Termina</strong>
                    </div>
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div class="history h-100 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-6">
          <!-- History -->
          <div :class="{disabled: dashboardReadonly}" class="d-flex card rounded-custom h-100 overflow-hidden">
            <div class="card-header rounded-only-top bg-dark-violet text-white text-center text-uppercase"
              :class="{'refactor': isRefactor}">
              storico
            </div>
            <div class="card-body h-100 p-0">
              <HistoryList
                :prescription="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription"
              />
            </div>
          </div>
        </div>
        <div class="chat-projects h-100 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3">
          <div class="d-flex rounded-custom h-100">
              <div class="d-flex flex-column rounded-only-bottom w-100 h-100 position-relative">
                <ul :class="{'refactor': isRefactor}"
                  class="nav nav-pills" id="pills-tab" role="tablist" >
                  <li class="nav-item" role="presentation">
                    <button class="nav-link text-uppercase rounded-only-top active" id="chat-tab" data-bs-toggle="pill" data-bs-target="#chat" type="button" role="tab" aria-controls="chat" aria-selected="true">
                      <span class="me-1">chat</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation" v-if="isCurrentDepartment(departments.FINITURA)">
                    <button class="nav-link text-uppercase rounded-only-top d-flex align-items-center" id="projects-tab" data-bs-toggle="pill" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">
                      <span class="me-1">progetti</span>
                    </button>
                  </li>
                  <div class="close" @click="restoreWidth()">
                    <img src="@/assets/icons/x-circle-fill-violet.svg" width="35" alt="x-circle-fill-violet-add.svg">
                  </div>
                </ul>
                <div class="tab-content bg-white h-100 rounded-only-bottom rounded-only-top-right" id="pills-tabContent">
                  <div class="chat h-100 tab-pane fade show active" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                    <template v-if="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription !== null">
                      <Conversation
                        :prescription="testWorkQueuePhase.data.test_work_queue.prescription_test.prescription"
                      />
                    </template>
                    <template v-else>
                      <Spinner />
                    </template>
                  </div>
                  <div class="projects tab-pane fade h-100 overflow-auto" id="projects" role="tabpanel" aria-labelledby="project-tab">
                    <template v-if="isCurrentDepartment(departments.FINITURA) && apiParams">
                      <ProjectsAndScene :api-params="apiParams" />
                    </template>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- HALF CIRCLE -->
      <template v-if="showTabIcon">
        <HalfCircle
          @action="openChat()"
        />
      </template>
    </template>
    <!-- Warehouse -->
    <template v-if="showWareHouse">
      <div class="row h-75">
        <div class="products h-100 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 p-2">
          <div class="card shadow rounded-custom h-100">
            <div class="card-header rounded-only-top bg-dark-violet p-3 text-center"
              :class="{'refactor': isRefactor}">
              <h3 class="text-white text-uppercase m-0">prodotti</h3>
            </div>
            <div class="d-flex flex-column card-body rounded-custom p-0 overflow-hidden">
              <GlobalSearch
                class="p-3"
                :label="'Ricerca prodotti'"
                :placeholder="'Ricerca categoria, prodotto...'"
                @toSearch="readValueToSearch"
              />
              <Products
                :search="toSearch"
                @add="addToOrders"
              />
            </div>
          </div>
        </div>
        <div class="orders h-100 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 p-2">
          <div class="card shadow rounded-custom h-100">
            <div class="card-header rounded-only-top bg-dark-violet p-3 text-center border-0"
              :class="{'refactor': isRefactor}">
              <h3 class="text-white text-uppercase m-0">ordine</h3>
            </div>
            <div class="d-flex flex-column card-body p-0 overflow-hidden">
              <Order
                :data="order"
                :warehouse-request-update="pusherRequests['warehouse_request_change-state']"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <!-- Components -->
  <Modal v-if="historyShowModal"
         :size="'xl'"
         :modal_id="'history'"
         :title="'Lavori precedenti'"
         :subtitle="'Esiste una corrispondenza con lavori precedentemente svolti'"
         :decline_label="null"
         :lightLayout="true"
         @hidden="historyCloseModal">
    <LastAssets
      :data="previousAssets"
      :first-name="prescription.customer.first_name"
      :last-name="prescription.customer.last_name"
    />
  </Modal>

  <InternalNotes v-if="showHistoryInternalNotes"
                 :title="testWorkQueuePhase.data.test_work_queue.prescription_test.name + ' / Note interne LAB'"
                 :test_work_queue_id="testWorkQueuePhase.data.test_work_queue.id"
                 @historyNotes="modalNotesHide">
  </InternalNotes>

  <Completed v-if="completed"
             :test_work_queue_phase_id="$props.test_work_queue_phases_id"
             :test_work_queue_id="testWorkQueuePhase.data.test_work_queue.id"
             :is_last="testWorkQueuePhase.data.children_test_work_queue_phases.length <= 0"
             :children_test_work_queue_phase="testWorkQueuePhase.data.children_test_work_queue_phases"
             @hidden="modalCompletedHide">
  </Completed>

  <!-- Clipboard modal -->
  <Empty v-if="showClipboard"
         :size="'md'"
         :modal_id="'specialSuspendModal'"
         @hidden="closeClipboardModal">
    <div class="mb-3 p-3">
      <label for="clipboard" class="form-label">
        Appunta le tue note qui!</label>
      <textarea
        class="form-control"
        id="clipboard"
        rows="10"
        v-model="clipboard">
    </textarea>
    </div>
  </Empty>

  <!-- Special suspend modal -->
  <Empty v-if="showSpecialSuspend"
        :size="'md'"
        :modal_id="'specialSuspendModal'"
        @hidden="closeSpecialSuspendModal">
    <SpecialActions
      style="height: 400px"
      title="Seleziona il motivo della tua richiesta"
      :modelNameSelect="specialMotivationIdSelected"
      :placeholderNameSelect="'Seleziona la motivazione'"
      :optionsSelect="specialMotivations"
      :notes="showNotes"
      @selected="setMotivationIdSelected"
      @clear="clearSpecialMotivationSelected"
      @store="storeSpecialMotivation"
      @close="closeSpecialMotivationModal"
    >
      <template v-if="showRejectedSelect">
        <MultiselectCustom
          id="rejectedSelect"
          class="multiselect-violet mb-3"
          v-model="rejectedReasonIdSelected"
          :searchable="true"
          :placeholder="'Seleziona uno'"
          :options="rejectedReasons"
        />
      </template>
    </SpecialActions>
  </Empty>

  <!-- Vendors modal -->
  <Empty v-if="showVendorsModal"
         :size="'md'"
         :modal_id="'vendorsModal'"
         @hidden="closeExternalVendorsModal">
    <SpecialActions
      style="height: 400px"
      :title="'Seleziona il fornitore'"
      :modelNameSelect="vendorIdSelected"
      :placeholderNameSelect="'seleziona...'"
      :optionsSelect="vendors"
      @selected="setVendorIdSelected"
      @clear="clearVendorSelected"
      @store="storeVendor"
      @close="closeVendorsModal"
    />
  </Empty>

  <!-- All notes modal -->
  <Modal v-if="showAllNotesModal"
       class="overflow-auto"
       size="lg"
       modal_id="historyNotes"
       title="Tutte le note"
       decline_label="chiudi"
       :lightLayout="false"
       :disabled="!readAll"
       :options="readAll ? {} : {backdrop: 'static', keyboard: false}"
       @hidden="closeAllNotesModal">
  <Notes
    :prescription-test-id="prescription.current_test_id"
    :notes="prescriptionTestNotes"
    :readConfirmation="true"
    @readAll="setReadAll"
  />
</Modal>

</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  computed,
  getCurrentInstance,
  onUnmounted,
  watch,
  inject,
} from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { useStore } from "vuex";
import _head from "lodash/head";
import _filter from "lodash/filter";
import _cloneDeep from "lodash/cloneDeep";
import _omit from "lodash/omit";
import _some from "lodash/some";
import _get from "lodash/get";

import moment from "moment";

import Brand from '@/components/Brand';
import Spinner from "@/components/general/Spinner";
import Completed from "@/components/dashboard/Completed";
import InternalNotes from "@/components/InternalNotes";
import LastAssets from "@/components/Project/LastAssets";
import Slider from "@/components/general/Slider";
import HistoryList from "@/components/HistoryList";
import Elements from "@/components/Project/Elements";
import Modal from "@/components/Modal/Standard";
import GlobalSearch from "@/components/GlobalSearch";
import Products from "@/components/warehouse/technician/Products";
import Order from "@/components/warehouse/technician/Order";
import Situ from "@/components/Situ";
import Empty from "@/components/Modal/Empty";
import SpecialActions from "@/components/SpecialActions";
import MainButton from "@/components/Manager/MainButton";
import Confirm from "@/components/Modal/Confirm";
import HalfCircle from "@/components/general/HalfCircle";
import Conversation from "@/components/chat/Conversation";
import NotesIcon from "@/components/Icons/Notes";
import Notes from "@/components/Notes";
import ProjectsAndScene from "@/components/dashboard/ProjectsAndScene.vue";
import MultiselectCustom from "@vueform/multiselect";

import { update as updateTestWorkQueuePhase } from '@/use/repositories/testWorkQueuePhase/update';
import { update as updateState } from '@/use/repositories/testWorkQueue/update';
import { store as storeFeedback } from "@/use/repositories/feedbacks/store";
import { fetchById as fetchTestWorkQueuePhaseById } from '@/use/repositories/testWorkQueuePhase/fetchById';
import { fetchById as fetchCharacterization } from "@/use/repositories/prescriptions/fetchById.js";
import { hideModal as closeModal } from '@/use/modal/hide';
import { departmentException } from '@/use/departmentException';
import { changeState } from '@/use/repositories/users/changeState';
import { fetchByRole as fetchVendors } from "@/use/repositories/users/fetchByRole";
import { externalPhases } from "@/use/repositories/testWorkQueuePhase/externalPhases";
import { fetchByType } from '@/use/repositories/feedbacks/fetchByType';
import { fetchByDepartmentSlug } from "@/use/repositories/testWorkQueuePhase/fetchByDepartmentSlug";
import { fetchNotes } from "@/use/repositories/prescriptions/fetchNotes";
import { useI18n } from "vue-i18n";
import { warning } from "@/use/toast/warning";
import { success } from "@/use/toast/success";
import { fetchRejectedReasons } from "@/use/repositories/prescriptionTestRejected/fetchRejectedReasons";
import { storeAsRejected } from "@/use/repositories/prescriptionTestRejected/storeAsRejected";
import { isRefactorPrescription } from "@/use/utilities/isRefactorPrescription";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
import { hasRole } from "@/use/utilities/users/hasRole";

export default {
  name: 'Dashboard',
  components: {
    Brand,
    Conversation,
    HalfCircle,
    Order,
    Completed,
    InternalNotes,
    HistoryList,
    Slider,
    Elements,
    LastAssets,
    Modal,
    GlobalSearch,
    Products,
    Situ,
    Empty,
    SpecialActions,
    MainButton,
    Confirm,
    NotesIcon,
    Notes,
    ProjectsAndScene,
    Spinner,
    MultiselectCustom,
},
  props: [
    'test_work_queue_phases_id',
  ],
  setup(props) {
    const { departments, materials, assetsType, states, userStates } = inject("constants");
    const testWorkQueuePhase = reactive({data: null});
    const technicianStatus = ref(null);
    const userData = reactive({});
    const store = useStore();
    const router = useRouter();
    const prescription = reactive({
      id: '',
      number_text: '',
      created_at: null,
      characterizations: [],
      customer: {},
      current_test_id: undefined,
    });
    const completed = ref(false);
    const showHistoryInternalNotes = ref(false);
    const historyShowModal = ref(false);
    const atLeastOneFileUploaded = ref(false);
    const allFilesUploaded = ref(false);
    const showPartialCompletionModal = ref(false);
    let dashboardReadonly = departmentException(store.state.userData);
    const showWareHouse = ref(false);
    const toSearch = ref("");
    const order = ref(null);
    // Pusher request.
    const pusherRequests = ref({
      'warehouse_request_change-state': null,
    });
    // Pusher instance.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const showSpecialSuspend = ref(false);
    const showRejectPrescriptionTestModal = ref(false);
    const showVendorsModal = ref(false);
    const specialMotivationIdSelected = ref(null);
    const vendorIdSelected = ref(null);
    const specialMotivations = ref([]);
    const rejectedReasons = ref([]);
    const rejectedReasonIdSelected = ref([]);
    const showRejectedSelect = ref(false);
    const vendors = ref([]);

    const canSuspend = [
      departments.SCANSIONE,
      departments.FINITURA,
      departments.MOBILE,
      departments.PREPARAZIONE,
    ].includes(store.state.mainRole.team.name);

    const showNotes = ref(true);
    const showClipboard = ref(false);
    const clipboard = ref("");
    const dataManagerCount = ref(0);
    const showTabIcon = ref(false);
    const showAllNotesModal = ref(false);
    const prescriptionTestNotes = ref([]);
    const readAll = ref(false);
    const showNotesIcon = ref(false);
    const modelInitialState = {
      must_upload: false,
      material: {
        slug: materials.PRINTED_RESIN,
      },
      prescription: {},
      arches: [],
      gums: [],
      stumps: [],
      custom_pins: []
    };

    const models = reactive(_cloneDeep(modelInitialState));
    const modelTypes = ref(_omit(assetsType, 'MILLING'));

    const allModelsIsUploaded = ref(false);
    const i18n = useI18n();

    const isRefactor = computed(() => {
      return isRefactorPrescription(prescription?.number_text);
    })

    const apiParams = ref(null);

    // Department slug.
    const showSITU = computed(() => {
      return store.state.mainRole.team.name === departments.PREPARAZIONE;
    });

    const testWorkQueuePhaseChild = computed(() => {
      if (testWorkQueuePhase.data.children_test_work_queue_phases.length > 0) {
        return _head(testWorkQueuePhase.data.children_test_work_queue_phases);
      }

      return {};
    });

    const resetModels = () => {
      const clonedInitialState = _cloneDeep(modelInitialState);
      Object.assign(models, clonedInitialState);
    }

    const forceModelsHandle = (value) => {
      if (value) {
        storeDefaultModels();
      } else {
        resetModels();
      }
    }

    const storeDefaultModels = () => {
      models.must_upload = true;
      // Add prescription info.
      models.prescription = {
        id: testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.id,
        colorScale: testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.color,
      };

      // Arches prefill.
      models.arches.push({
        name: "Arcata superiore",
        slug: assetsType.UPPER_ARCH,
        application: "Arcata",
        zone_number: "sup",
        assets: getAssetsBySlug(testWorkQueuePhase.data.assets, assetsType.UPPER_ARCH),
        selected: false,
        icon: "arch/arch-sup",
        type: "arches",
      });

      models.arches.push({
        name: "Arcata inferiore",
        slug: assetsType.LOWER_ARCH,
        application: "Arcata",
        zone_number: "Inf",
        assets: getAssetsBySlug(testWorkQueuePhase.data.assets, assetsType.LOWER_ARCH),
        selected: false,
        icon: "arch/arch-inf",
        type: "arches",
      });
    }

    // Check if the prescription rows are the same.
    const previousAssets = computed(() => {
      // Get previous prescription rows.
      let previousPrescriptionRows = testWorkQueuePhase?.data?.test_work_queue?.prescription_test?.prescription?.customer?.assets;

      let innerPrescriptionRows = [];
      if (previousPrescriptionRows && previousPrescriptionRows.length > 0) {
        previousPrescriptionRows.forEach((element) => {
          element?.prescription_rows?.forEach((innerElement) => {
            // Push data to the array.
            innerPrescriptionRows.push({
              asset_id: element.id,
              test_work_queue_phase_id: element.test_work_queue_phase_id,
              created_at: element.created_at,
              number_text: element.prescription?.number_text,
              material: element.prescription?.material,
              bridge: innerElement.bridge,
              zone_number: innerElement.zone_number,
              application: innerElement.application,
            });
          });
        });
      }

      return innerPrescriptionRows;
    });

    watch(() => clipboard.value, (value) => {
      // Set clipboard on vuex.
      store.dispatch("setClipboardDashboardTechnician", value);
    });
    watch(() => store.state.clipboardDashboardTechnician, (valueOnStore) => {
      clipboard.value = valueOnStore;
    });

    // Pusher.
    const subscribe = (channelName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      const callback = (eventName, data) => {
        switch (eventName) {
          case "warehouse_request_change-state":
            pusherRequests.value[eventName] = data;
            break;
          case "manager_change_phase_state":
            if ([
              states.SENT_TO_EXTERNAL,
              states.ON_HOLD,
            ].includes(data.state.slug)) {
              dataManagerCount.value++;
            }
            break;
        }
        //console.log(`bind global channel: The event ${eventName} was triggered with data ${JSON.stringify(data)}`);
      };
      // bind to all events on the channel
      channel.bind_global(callback);
    };

    const existsDataManager = (data) => {
      dataManagerCount.value = _filter(data, function(testWorkQueuePhase) {
        if ([
          states.ON_HOLD,
          states.SENT_TO_EXTERNAL,
        ].includes(testWorkQueuePhase.state.slug)) {
          return testWorkQueuePhase;
        }
      }).length;
    }

    // Hide the partial completion confirmation modal, i.e. the modal
    // that asks the user if he wants to complete the test work queue phase.
    const hidePartialCompletionModal = (value) => {
      showPartialCompletionModal.value = value;
    };

    onBeforeRouteUpdate(async (to) => {
      if (router.currentRoute.value.path !== to.href) {
        // Reload.
        window.location.replace(to.href);
      }
    });

    onMounted(() => {
      if (hasRole('manager')) {
        const statesSlug = [states.ON_HOLD, states.IN_THE_WORKS];
        if (isCurrentDepartment(departments.MOBILE)) {
          statesSlug.push(states.SENT_TO_EXTERNAL);
        }
        // Fetch manager counter.
        fetchByDepartmentSlug(
          store.state.mainRole.team.name,
          statesSlug,
          'by_department_and_states'
        ).then((response) => {
          existsDataManager(response.departmentTestWorkQueuePhases.test_work_queue_phases);
        });
      }

      // Set clipboard value if exists in vuex.
      if (store.state.mainRole.team.name !== null) {
        clipboard.value = store.state.clipboardDashboardTechnician;
      }

      // Enabled warehouse boxes.
      if (isCurrentDepartment(departments.CAM)) {
        // Activate warehouse tab.
        showWareHouse.value = true;
        return;
      }

      getUserWorkQueuePhases();

      // Subscribe to channel.
      subscribe(`presence-user.${store.state.user.id}`);
    });

    const closeAllNotesModal = () => { showAllNotesModal.value = false }

    // Unsubscribe to channel.
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {
      $pusher: pusher,
      });
      pusher.unsubscribe(channelName);
    };

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel(`presence-user.${store.state.user.id}`);
    });

    const getAction = () => {
      if (isCurrentDepartment(departments.PROGETTAZIONE)) {
        return "with_assets";
      }

      return "with_all_relationships";
    }

    const getAssetsBySlug = (assets, typeSlug) => {
      return assets.filter((asset) => {
        return asset.type.slug === typeSlug;
      });
    }

    // Get test work queue phases assigned.
    const getUserWorkQueuePhases = async () => {
      await fetchTestWorkQueuePhaseById(props.test_work_queue_phases_id, getAction()).then(async (response) => {
        testWorkQueuePhase.data = response.testWorkQueuePhase;

        prescription.id = _get(testWorkQueuePhase, 'data.test_work_queue.prescription_test.prescription.id', '');
        prescription.current_test_id = _get(testWorkQueuePhase, 'data.test_work_queue.prescription_test.prescription.current_test_id', '');
        prescription.number_text = _get(testWorkQueuePhase, 'data.test_work_queue.prescription_test.prescription.number_text', '');
        prescription.created_at = _get(testWorkQueuePhase, 'data.test_work_queue.prescription_test.prescription.creation_date', '');
        prescription.customer = _get(testWorkQueuePhase, 'data.test_work_queue.prescription_test.prescription.customer', {});

        await fetchCharacterization(testWorkQueuePhase?.data?.test_work_queue?.prescription_test?.prescription?.id, 'with_teeth_characterizations').then((response) => {
          prescription.characterizations =  response.prescription.teeth_characterizations;
        });

        apiParams.value = {
          prescription_test_id: prescription.current_test_id,
          action: 'by_prescription_test',
        };
        // Get notes.
        getNotes();
        // Fetch all notes by prescription id.
        fetchNotes(prescription.id).then((response) => {
          if (_some(response.notes.prescription_tests, 'laboratory_notes.length')) {
            showNotesIcon.value = true;
          }
        });

        // Retrieve the information only if the parent is of type ISO or if you have intraoral scan.
        if (testWorkQueuePhase.data.test_work_queue.is_model_required) {
          let modelsAssets = testWorkQueuePhase.data.assets;
          // Default models load
          storeDefaultModels();

          // Gum prefill.
          let assetsGums = getAssetsBySlug(modelsAssets, assetsType.GUM);
          if (assetsGums.length > 0) {
            for (let i = 0; i < assetsGums.length; i++) {
              models.gums.push({
                name: "Gengiva",
                slug: `${assetsType.GUM}${i}`,
                application: "Gengiva",
                assets: [],
                selected: false,
                icon: "gum/gum",
                type: "gums",
              });
            }
          }
          assetsGums.forEach((asset, index) => {
            models.gums[index].assets.push(asset);
          });


          // Stump prefill.
          let assetsStumps = getAssetsBySlug(modelsAssets, assetsType.STUMP);
          if (assetsStumps.length > 0) {
            for (let i = 0; i < assetsStumps.length; i++) {
              models.stumps.push({
                name: "Moncone",
                slug: `${assetsType.STUMP}${i}`,
                application: "Moncone",
                selected: false,
                assets: [],
                icon: "stump/stump",
                type: "stumps",
              });
            }
            assetsStumps.forEach((asset, index) => {
              models.stumps[index].assets.push(asset);
            });
          }

          // Custom-pins prefill.
          let assetsCustomPins = getAssetsBySlug(modelsAssets, assetsType.CUSTOM_PIN);
          if (assetsCustomPins.length > 0) {
            for (let i = 0; i < assetsCustomPins.length; i++) {
              models.custom_pins.push({
                name: "Perno Custom",
                slug: `${assetsType.CUSTOM_PIN}${i}`,
                application: "Perno Custom",
                selected: false,
                assets: [],
                icon: "custom-pin/custom-pin",
                type: "custom-pins",
              });
            }
            assetsCustomPins.forEach((asset, index) => {
              models.custom_pins[index].assets.push(asset);
            });
          }
        }
      });
    };

    const getNotes = () => {
      // Fetch all notes by prescription id.
      fetchNotes(prescription.id).then((response) => {
        // Reverse prescription test order.
        prescriptionTestNotes.value = response.notes.prescription_tests.reverse();
        // Show modal if exists notes.
        prescriptionTestNotes.value.forEach((prescriptionTest) => {
          if (prescriptionTest.laboratory_notes.length && (prescriptionTest.id === prescription.current_test_id)) {

            // Check if already read all notes.
            let counter = 0;
            let allNotesLength = prescriptionTest.laboratory_notes.length;

            prescriptionTest.laboratory_notes.forEach((note) => {
              const usersUnique = [...new Set(note.users.map(user => user.id))];
              if (usersUnique.includes(store.state.user.id)) {
                return counter++;
              }
            });

            readAll.value = (counter === allNotesLength);

            if (!readAll.value) {
              // Open modal notes.
              showAllNotesModal.value = true;
            }
          }
        });
      });
    }

    watch(() => readAll.value, (value) => {
      if (!value) {
        // Open modal notes.
        showAllNotesModal.value = true;
      }
    });

    /**
     * Mark the test work queue phase as completed.
     */
    const completeInternalPhase = () => {
      completed.value = true;
    };

    /**
     * Work queue phase in completion.
     * Let's check uploaded assets if CAD.
     */
    const toFinish = () => {

      // Check only models exists.
      if (models.must_upload) {
        if (isCurrentDepartment(departments.PROGETTAZIONE) && !allModelsIsUploaded.value) {
          return warning(i18n.t('Before continuing, complete the upload of all MODELS in the UPLOAD FILE section'));
        }
      }

      if (allFilesUploaded.value || !isCurrentDepartment(departments.PROGETTAZIONE)) {
        completeInternalPhase();
      } else if (atLeastOneFileUploaded.value) {
        showPartialCompletionModal.value = true;
      } else {
        warning(i18n.t('Before continuing, complete the upload of all files in the UPLOAD FILE section'));
      }
    }

    const requestExternal = () => {
      if (isCurrentDepartment(departments.PROGETTAZIONE) && !allFilesUploaded.value) {
        return warning(i18n.t('Before continuing, complete the upload of all files in the UPLOAD FILE section'));
      }

      if (! isCurrentDepartment(departments.MOBILE)) {
        // Get vendors.
        fetchVendors('vendor', 'with_test_work_queue_phases').then((response) => {
          vendors.value = response.users.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
            };
          });

          showVendorsModal.value = true;
        });
      } else {
        // Update test work queue.
        updateTestWorkQueuePhase({
            source_ids: [parseInt(props.test_work_queue_phases_id)],
            state: states.SENT_TO_EXTERNAL,
            action: "update",
          }).then(async () => {
          // Show message.
          setTimeout(() => {
            success(i18n.t('Request sent to manager successfully!'));
          }, 3000);

          // Change user state.
          changeState(userStates.AVAILABLE).then(async () => {
            // Redirect to check in.
            return router.push({ name: "Checkin" });
          });
        });
      }
    }

    const rejectPrescriptionTest = async () => {
      fetchRejectedReasons().then((response) => {
        rejectedReasons.value = response.rejectedReasons;
      });
    }

    watch(() => specialMotivationIdSelected.value, async (value) => {
      showRejectedSelect.value = false;
      const foundReason = specialMotivations.value.find((reason) => reason.value == value);

      if (foundReason && foundReason.slug === 'rejected') {
        await rejectPrescriptionTest().then(() => {
          showRejectedSelect.value = true;
        });
      }
    });

    const managerRequest = (type) => {
      fetchByType(type, {
        action: 'with_feedbacks',
        internal_phase_id:  testWorkQueuePhase.data.internal_phase.id,
        state_slug: states.ON_HOLD,
      }).then((response) => {
        specialMotivations.value = response.feedbacks;
        // Show special suspend modal.
        showSpecialSuspend.value = true;
      });
    }

    const requestSpecialSuspend = () => {
      fetchByType('resumable', {
        action: 'with_feedbacks',
        internal_phase_id:  testWorkQueuePhase.data.internal_phase.id,
        state_slug: states.ON_HOLD,
      }).then((response) => {
        if (response.feedbacks.length) {
          specialMotivations.value = response.feedbacks;
          // Show special suspend modal.
          showSpecialSuspend.value = true;
          // Disabled notes on component.
          showNotes.value = false;
        } else {
          // Show message
          warning(i18n.t('No reasons for suspension found!'));
        }
      });
    }

    const storeRejected = () => {
      const reasonSlug = rejectedReasons.value.find((reason) => reason.value == rejectedReasonIdSelected.value).slug;
      storeAsRejected(testWorkQueuePhase.data.test_work_queue.prescription_test.id.toString(), 'pending', reasonSlug);
    }

    const storeSpecialMotivation = (note) => {
      storeFeedback({
        test_work_queue_phase_id: parseInt(props.test_work_queue_phases_id),
        state: states.ON_HOLD,
        is_iso: Boolean(false),
        note: note,
        feedback_id: specialMotivationIdSelected.value,
        action: "without_media",
      }).then(async () => {
      // Change user state.
      changeState(userStates.AVAILABLE).then(async () => {
        if (showRejectedSelect.value) {
          updateState({
            id: testWorkQueuePhase.data.test_work_queue.id,
            state_slug:"non-complient",
            action: "update_state"
          });
          storeRejected();
        }
        // Destroy modal instance.
        closeSpecialMotivationModal();
        // Redirect to check in.
        return router.push({ name: "Checkin" });
      });
    });
    }

    const storeVendor = () => {
      externalPhases(
        parseInt(props.test_work_queue_phases_id),
        isCurrentDepartment(departments.MOBILE),
        vendorIdSelected.value,
      ).then(async () => {
        updateTestWorkQueuePhase({
            source_ids: [parseInt(props.test_work_queue_phases_id)],
            state: states.COMPLETED,
            action: "update",
          }).then(async () => {
          // Change user state.
          changeState(userStates.AVAILABLE).then(async () => {
            // Destroy modal instance.
            closeVendorsModal();
            // Redirect to check in.
            return router.push({ name: "Checkin" });
          });
        });
      });
    }

    const rememberNote = () => {
      showClipboard.value = true;
    }

    const restoreWidth = () => {
      let currentWork = document.getElementsByClassName('current-work');
      let history = document.getElementsByClassName('history');
      let chatBox = document.getElementsByClassName('chat-projects');

      _head(currentWork).classList.remove('col-xxl-3');
      _head(currentWork).classList.add('col-xxl-4');

      _head(history).classList.remove('col-xxl-6');
      _head(history).classList.add('col-xxl-7');

      _head(chatBox).classList.add('d-none');

      showTabIcon.value = true;
    }

    const openChat = () => {
      let currentWork = document.getElementsByClassName('current-work');
      let history = document.getElementsByClassName('history');
      let chatBox = document.getElementsByClassName('chat-projects');

      _head(currentWork).classList.remove('col-xxl-4');
      _head(currentWork).classList.add('col-xxl-3');

      _head(history).classList.remove('col-xxl-7');
      _head(history).classList.add('col-xxl-6');

      _head(chatBox).classList.remove('d-none');

      showTabIcon.value = false;
    }

    const closeSpecialMotivationModal = () => {
      closeModal('specialSuspendModal');
    }
    const closeVendorsModal = () => {
      closeModal('vendorsModal');
    }

    // Read emits.
    const modalCompletedHide = (value) => {
      completed.value = value
    };
    const modalNotesHide = (value) => {
      showHistoryInternalNotes.value = value
    };
    const historyCloseModal = (value) => {
      historyShowModal.value = value;
    }

    // Read if at least one asset has been uploaded.
    const readAtLeastOneFileIsUploaded = (value) => {
      atLeastOneFileUploaded.value = value;
    };

    const setReadAll = (value) => {
      readAll.value = value;
    }

    const readAllFilesIsUploaded = (value) => {
      allFilesUploaded.value = value;
    }
    const readValueToSearch = (value) => {
      toSearch.value = value;
    }
    const addToOrders = (article) => {
      order.value = article;
    }
    const closeRejectPrescriptionTestModal = () => {
      showRejectPrescriptionTestModal.value = false;
      specialMotivationIdSelected.value = null;
    }
    const closeSpecialSuspendModal = () => {
      showSpecialSuspend.value = false;
      specialMotivationIdSelected.value = null;
    }
    const closeClipboardModal = () => {
      showClipboard.value = false;
    }
    const closeExternalVendorsModal = () => {
      showVendorsModal.value = false;
      vendorIdSelected.value = null;
    }
    const clearSpecialMotivationSelected = () => {
      vendorIdSelected.value = null;
    }
    const clearVendorSelected = () => {
      specialMotivationIdSelected.value = null;
    }
    const setVendorIdSelected = (vendorId) => {
      vendorIdSelected.value = vendorId;
    }
    const setMotivationIdSelected = (motivationId) => {
      specialMotivationIdSelected.value = motivationId;
    }

    const clearModelSelected = () => {
      models.arches.forEach((arch) => arch.selected = false);
      models.gums.forEach((gum) => gum.selected = false);
      models.stumps.forEach((stump) => stump.selected = false);
      models.custom_pins.forEach((pin) => pin.selected = false);
    }

    const syncModelsData = (obj) => {
      console.log(`REMOVE ELEMENT OF TYPE: ${obj.type}`);
      let index = models[obj.type].findIndex((el) => el.slug === obj.slug);
      models[obj.type][index].assets.push(obj.asset);
    }

    // TODO: OPTIMIZE
    const removeAssetsModel = (ids) => {
      models.arches.forEach((arch) => {
        if (arch.assets.length > 0 && ids.includes(_head(arch.assets).id)) {
          arch.assets = [];
        }
      });
      models.gums.forEach((gum) => {
        if (gum.assets.length > 0 && ids.includes(_head(gum.assets).id)) {
          gum.assets = [];
        }
      });
      models.stumps.forEach((stump) => {
        if (stump.assets.length > 0 && ids.includes(_head(stump.assets).id)) {
          stump.assets = [];
        }
      });
      models.custom_pins.forEach((pin) => {
        if (pin.assets.length > 0 && ids.includes(_head(pin.assets).id)) {
          pin.assets = [];
        }
      });
    }

    // TODO: OPTIMIZE
    const addStump = () => {
      models.stumps.push({
        name: "Moncone",
        slug: `${assetsType.STUMP}${new Date().getTime()}`,
        application: "Moncone",
        selected: false,
        assets: [],
        icon: "stump/stump",
        type: "stumps",
      });
    }

    const removeStump = () => {
      let index = models.stumps.findIndex((stump) => stump.assets.length === 0);
      if (index !== -1) {
        models.stumps.splice(index, 1);
        clearModelSelected();
      }
    }

    const addGum = () => {
      models.gums.push({
        name: "Gengiva",
        slug: `${assetsType.GUM}${new Date().getTime()}`,
        application: "Gengiva",
        assets: [],
        selected: false,
        icon: "gum/gum",
        type: "gums",
      });
    }

    const removeGum = () => {
      let index = models.gums.findIndex((gum) => gum.assets.length === 0);
      if (index !== -1) {
        models.gums.splice(index, 1);
        clearModelSelected();
      }
    }

    const addCustomPin = () => {
      models.custom_pins.push({
        name: "Perno Custom",
        slug: `${assetsType.CUSTOM_PIN}${new Date().getTime()}`,
        application: "Perno Custom",
        selected: false,
        assets: [],
        icon: "custom-pin/custom-pin",
        type: "custom-pins",
      });
    }

    const removeCustomPin = () => {
      let index = models.custom_pins.findIndex((pin) => pin.assets.length === 0);
      if (index !== -1) {
        models.custom_pins.splice(index, 1);
        clearModelSelected();
      }
    }

    const readAllModelsIsUploaded = (value) => {
      allModelsIsUploaded.value = value;
    }

    return {
      showTabIcon,
      restoreWidth,
      testWorkQueuePhase,
      getUserWorkQueuePhases,
      technicianStatus,
      completed,
      modalCompletedHide,
      modalNotesHide,
      showHistoryInternalNotes,
      userData,
      prescription,
      dashboardReadonly,
      previousAssets,
      historyCloseModal,
      historyShowModal,
      readAllFilesIsUploaded,
      readAtLeastOneFileIsUploaded,
      hidePartialCompletionModal,
      completeInternalPhase,
      showPartialCompletionModal,
      toFinish,
      showWareHouse,
      readValueToSearch,
      toSearch,
      addToOrders,
      order,
      pusherRequests,
      allFilesUploaded,
      showSITU,
      testWorkQueuePhaseChild,
      showSpecialSuspend,
      closeSpecialSuspendModal,
      specialMotivationIdSelected,
      clearSpecialMotivationSelected,
      clearVendorSelected,
      specialMotivations,
      storeSpecialMotivation,
      closeSpecialMotivationModal,
      closeVendorsModal,
      showVendorsModal,
      closeExternalVendorsModal,
      vendorIdSelected,
      requestExternal,
      vendors,
      storeVendor,
      setVendorIdSelected,
      setMotivationIdSelected,
      managerRequest,
      requestSpecialSuspend,
      canSuspend,
      showNotes,
      moment,
      rememberNote,
      showClipboard,
      clipboard,
      closeClipboardModal,
      hasRole,
      dataManagerCount,
      openChat,
      showAllNotesModal,
      closeAllNotesModal,
      prescriptionTestNotes,
      readAll,
      setReadAll,
      showNotesIcon,
      models,
      clearModelSelected,
      syncModelsData,
      removeAssetsModel,
      readAllModelsIsUploaded,
      addStump,
      removeStump,
      apiParams,
      addGum,
      removeGum,
      addCustomPin,
      removeCustomPin,
      forceModelsHandle,
      modelTypes,
      rejectPrescriptionTest,
      closeRejectPrescriptionTestModal,
      storeRejected,
      rejectedReasons,
      rejectedReasonIdSelected,
      showRejectedSelect,
      isRefactor,
      isCurrentDepartment,
      departments,
    };
  },
};
</script>

<style scoped lang="scss">
  @import "@/scss/abstracts/_variables.scss";

  .nav-link, .nav-link:hover {
    color: $dark_violet;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #7E6492;
  }
  .nav-pills.refactor .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #301934;
  }
  .chat-projects {order: 3;}
  .history {order: 2;}
  .current-work {order: 1;}
  .products {order: 1;}
  .orders {order: 2;}
  h1 {
    font-size: 5.5rem;
  }
  #notesIcon:hover {
      cursor: pointer;
  }
  .vertical-line {
    width: 0.1%;
    background-color: gray;
  }
  .btn-outline-violet:hover {
    color: #A58ABA !important;
    background-color: #fff !important;
    border-color: #A58ABA !important;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  #history-customer {
    z-index: 999;
  }
  #customer {
    z-index: 1;
    position: relative;
  }
  #blob {
    position: absolute;
    z-index: -1;
    transform: rotate(368deg);
    top: -77%;
    left: -8%;
  }
  #icon-box {
    padding: 10px;
    background-color: #A58ABA;
    border-radius: 50%;
    position: absolute;
    top: -50%;
    left: -10%;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  #icon-box:hover {
    transform: scale(1.1);
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .close:hover {
    cursor: pointer;
  }
  .close {
    position: absolute;
    right: 5px;
    top: 0;
  }
  [class*="col-"] {
    margin-bottom: 15px;
  }
  .card-header.refactor,
  .card-footer.refactor {
    background-color: #301934 !important;
  }

  @media screen and (max-width: 1400px) {
    .chat-projects {display: block !important;}
    .btn-close-chat, .half-circle {display: none;}
    .close { display: none !important; }
  }
  @media screen and (max-width: 991px) {
    .chat-projects {order: 1;}
    .history {order: 2;}
    .current-work {order: 3;}
    .products {order: 2;}
    .orders {order: 1;}
  }
</style>
