<script>
import moment from "moment/moment";
import { dateFormatter } from '@/use/utilities/time/dateFormatter';
export default {
  name: 'ListItem',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      required: true,
    }
  },
  setup() {
    // USE last_message_at
    const getDateTimeLastMessage = (lastMessageAt) => {
      if (moment(lastMessageAt).isBefore(moment())) {
        return dateFormatter(lastMessageAt);
      }

      return dateFormatter(lastMessageAt, {onlyDate: false, onlyTime: true, dash: false, iso: false});
    }

    return { getDateTimeLastMessage }
  }
}
</script>

<template>
  <a href="javascript:void(0)" class="list-group-iem chat-item py-2 rounded-3" :class="{ 'active': $props.active }" aria-current="true">
    <div class="d-flex w-100 align-items-center justify-content-between">
      <span class="d-flex mx-2">
        <img src="@/assets/icons/logistic-checkin/customer.svg" width="50" height="50" alt="customer" class="m-1 me-3 border border-1 rounded-circle p-2">
        <span class="d-flex flex-column">
          <span class="fw-bold fs-15">{{ $props.message.chat_channelable.customer.first_name }} {{ $props.message.chat_channelable.customer.last_name }} - {{ $props.message.chat_channelable.clinic.name }}</span>
          <span class="mb-1 fs-15">{{ $props.message.name }}</span>
        </span>
      </span>
      <span class="d-flex flex-column mx-3">
        <span v-show="!$props.message.has_been_read" class="ms-auto badge rounded-pill bg-violet w-fit-content">
          {{ $props.message.messages.length }}
        </span>
        <small>{{ getDateTimeLastMessage($props.message.last_message_at) }}</small>
      </span>
    </div>
  </a>
</template>

<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.active {
  background-color: $home_violet;
  border: none;
}
.chat-item {
  color: black;
  &:hover {
    transition: 0.5s;
    background-color: $home_violet;
  }
}
.fs-15 {
  font-size: 15px;
}

.w-fit-content {
  width: fit-content;
}

a {
  text-decoration: none !important;
  ;
}</style>
