<script>
import { uuid } from "@/use/utilities/uuid";
import getAuthToken from "@/use/get-auth-token";
import { onMounted, getCurrentInstance } from "vue";

import QRCode from "@/components/QRCode";

export default {
  emits:['sync-files'],
  components: {
    QRCode
  },
  setup(props, {emit}) {
    const uniqueQrUuid = uuid();
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext?.config?.globalProperties?.$pusher;
    const externalDeviceRoute = `${window.location.origin}/external/device/chat/${uniqueQrUuid}?signature=${getAuthToken()}`;

    const parseCustomEvent = (eventName, data) => {
      console.log(`event received: ${eventName}`);
      emit('syncFiles', data.data );
    }

    onMounted((message) => {
      const channelName = "private-live";
      console.log(`subscribing  from "${channelName}"...`, { $pusher: pusher });
      let channel = pusher.subscribe(channelName);

      channel.bind_global(parseCustomEvent, message);
    });

    return { externalDeviceRoute, uniqueQrUuid };
  },
};
</script>

<template>
  <div>
    <QRCode :text="externalDeviceRoute" />
    <a :href="externalDeviceRoute" class="btn btn-secondary stretched-link" target="_blank">Link diretto</a>
  </div>
</template>
