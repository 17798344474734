<script>
import { show as showChannel } from "@/use/repositories/chat/channel/show";
import { show as showMessage } from "@/use/repositories/chat/message/show"
import ChatWidget from "@/components/primoChat/ChatWidget";

import { useStore } from "vuex";

import { ref, computed, getCurrentInstance, provide, reactive, onMounted } from "vue";

export default {
  name: "ToggleWidget",
  components: { ChatWidget },
  setup() {
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext?.config?.globalProperties?.$pusher;
    const showChat = ref(false);
    const message = ref("");
    const customEvent = reactive({ eventName: null, data: null });

    const showBadge = computed(() => store.state.userPersonalMessagesUnread > 0);

    const counter = computed(() => {
      const counter = store.state.userPersonalMessagesUnread;

      return counter > 99 ? "99+" : counter;
    });

    const isUserPersonalMessage = (channelId, messageId) => {
      return hasWroteMessage(channelId) && isToNotify(messageId);
    }

    const subscribe = (channelName, message) => {
      console.log(`subscribing  from "${channelName}"...`, { $pusher: pusher });
      let channel = pusher.subscribe(channelName);
      // Bind to all events on the channel
      channel.bind_global(updateCustomEvent, message);
    };

    const updateCustomEvent = (eventName, data) => {
      if (eventName === "messaging" && isNotMe(data.user_id)) {

        if (isUserPersonalMessage(data.chat_channel_id, data.id)) store.dispatch("increasePersonalMessagesCount", 1);

        customEvent.eventName = eventName;
        customEvent.data = data;
      }
    };

    const isNotMe = (id) => {
      return store.state.user.id != id
    }

    const hasWroteMessage = (id) => {
      let alreadyWroteMessage = false;

      showChannel(id, 'has_wrote_messages')
        .then(response => alreadyWroteMessage = response.channel?.meta?.messages_count > 0);

      return alreadyWroteMessage;
    }

    const isToNotify = (id) => {
      let allowedMessage = false;

      showMessage(id)
        .then(response => allowedMessage = response.message?.user_role.role_type !== "laboratory");

      return allowedMessage;
    }

    onMounted(() => subscribe(`presence-user.${store.state.user.id}`, message));

    provide("pusherEvent", customEvent);

    return { showChat, showBadge, counter };
  },
};
</script>

<template>
  <span
    class="position-relative d-flex flex-column align-items-center h-fit-content"
    @click="showChat = true"
  >
    <img class="chat cp m-1" src="@/assets/icons/chat/chat.svg" width="45" alt="chat" />
    <span
      v-if="showBadge"
      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-violet w-fit-content">
      {{ counter }}
      <span class="visually-hidden">unread messages</span>
    </span>
  </span>
  <ChatWidget v-if="showChat" @close="showChat = false" />
</template>
