<script>
import ChatComponent from './ChatComponent.vue';
import ControlBar from './ControlBar.vue';
import ListGroup from './ListGroup.vue';
import Empty from "@/components/Modal/Empty";

import _orderBy from "lodash/orderBy";
import _sortBy from "lodash/sortBy";
import _map from "lodash/map";
import _head from "lodash/head";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _sumBy from "lodash/sumBy";
import _get from "lodash/get";
import _find from "lodash/find";
import _set from "lodash/set";

import { useI18n } from "vue-i18n";
import { error } from "@/use/toast/error";
import { useStore } from "vuex";

import { unreadMessagesCount }  from "@/use/chat/unreadMessagesCount";
import { edit as editChannel } from "@/use/repositories/chat/channel/edit";
import { index as fetchMessages } from "@/use/repositories/chat/channel/index";
import { show as showChannel } from "@/use/repositories/chat/channel/show";
import { updateReadings } from "@/use/repositories/chat/message/updateReadings";

import { onMounted, onUnmounted, ref, provide, reactive, computed } from 'vue';



export default {
  emits: ['close'],
  components: {
    ChatComponent,
    ControlBar,
    ListGroup,
    Empty
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const searchTerm = ref('');
    const chatType = ref();
    const showChatWidget = ref(false);
    const mobileLayout = ref(false);
    const newMessages = ref([]);
    const loading = ref(false);
    const prescription = reactive({
      id: null,
      chat_channel: {},
      customer: {},
      care_plan_row: {},
      number_text: null,
      is_favorite: null,
    });
    const chatTypeActions = {
      "all_chat": "by_clinic_unseen",
      "auth_user_chat": "by_clinic_my_unseen",
      "favorite_chat": "by_favorite"
    };

    const newMessagesComputed = computed(() => {
      const messages = _filter(newMessages.value, (item) => _includes(item.name, searchTerm.value.trim()));

      return _orderBy(messages, o => o.last_message_at, 'desc');
    })

    const newMessagesCounter = computed(() => {
      return _sumBy(newMessages.value, channel => channel.has_been_read ? 0 : _get(channel, 'messages.length', 0));
    })

    const getLayoutType = () => {
      mobileLayout.value = window.matchMedia("(max-width: 991.98px)").matches ? true : false;
    }

    const handleChat = (channels) => {
      // Create default message object
      channels.forEach(element => {
        if (!element.messages.length) element.messages = [...element.messages, { created_at: null }]
      });

      newMessages.value = _sortBy(channels, channel => {
          channel.messages[channel.messages.length - 1 ].created_at, ['asc']
        }).reverse();

      // Manage reading badge display
      newMessages.value = _map(newMessages.value, (message) => {
        return {
          ...message, "has_been_read": _head(message.messages).created_at ? false : true
        }
      });

      loading.value = false;
    }

    const selectChatType = async (value = 'auth_user_chat') => {
      chatType.value = value;
      loading.value = true;

      resetPrescriptionInfo();

      const action = chatTypeActions[chatType.value] || "by_clinic_my_unseen";

      return await fetchMessages(action, true).then(response => {
        handleChat(response.data);
        if (chatType.value === 'auth_user_chat') store.dispatch("setUserPersonalMessagesUnread", unreadMessagesCount(response.data));
      });
    }

    const openConversation = (message) => {
      setPrescriptionInfo(message);

      // Conversation already read. Open conversation
      if (message.has_been_read) return;

      updateReadings(getMessagesIds(message)).then(() => {
        markAsRead(message.id);
        if (chatType.value === 'auth_user_chat') store.dispatch("decreasePersonalMessagesCount", message.messages.length);
      })
      .catch(() => error(i18n.t('An error has occurred. Contact the technical service!')));
    }

    const setPrescriptionInfo = async (message) => {
      await showChannel(message.id, 'user_extra').then(response => prescription.is_favorite = response.channel?.meta?.is_favorite);

      prescription.id = message.chat_channelable.id;
      prescription.chat_channel = { id: message.id };
      prescription.customer = message.chat_channelable.customer;
      prescription.care_plan_row = _head(message.chat_channelable.care_plan_rows);
      prescription.number_text = message.chat_channelable.number_text;
      prescription.clinic = message.chat_channelable.clinic.name;
    }

    const resetPrescriptionInfo = () => {
      newMessages.value = [];
      prescription.id = null;
      prescription.chat_channel = {};
      prescription.customer = {};
      prescription.care_plan_row = {};
      prescription.number_text = null;
      prescription.clinic = null;
      prescription.is_favorite = null;
    }

    const markAsRead = (id) => {
      const message = _find(newMessages.value, { 'id': id });
      // Mark to read.
      if (message) _set(message, 'has_been_read', true);
    }

    const getMessagesIds = (message) => {
      return message.messages.map((message) => message.id);
    }

    const toFavorite = (channel) => {
      const value = prescription.is_favorite === null ? true : !prescription.is_favorite;
      editChannel(channel.id, 'sync_favorite', value).then(() => prescription.is_favorite = value);
    }

    onMounted(() => {
      getLayoutType();
      selectChatType();
      window.addEventListener("resize", getLayoutType);
    });

    onUnmounted(() => window.removeEventListener("resize", getLayoutType));

    provide('mobileLayout', mobileLayout);
    provide('prescription', prescription);
    provide('chatType', chatType);

    return {
      mobileLayout,
      newMessages,
      prescription,
      newMessagesComputed,
      searchTerm,
      chatType,
      loading,
      openConversation,
      selectChatType,
      newMessagesCounter,
      showChatWidget,
      toFavorite
    };
  },
}
</script>
<template>
  <Empty
    :size="'fullscreen'"
    :modal_id="'chatWidget'"
    @hidden="$emit('close')">
      <div class="d-flex w-100 h-100">
        <ControlBar @select-chat-type="selectChatType" :counter="newMessagesCounter" />
        <ListGroup
          :messages="newMessagesComputed"
          @select-conversation="openConversation"
          :search="searchTerm"
          @update:search="searchTerm = $event"
          :spinner="loading"/>
        <ChatComponent @to-favorite="toFavorite"/>
      </div>
  </Empty>
</template>
<style lang="scss" scoped>
@media (max-width: 991.98px) {}
.dot {
  top: 5px;
  right: 0;
}
.w-fit-content {
  width: fit-content;
}
.h-fit-content {
  height: fit-content;
}
</style>
