<script>
import ListItem from '@/components/primoChat/ListItem.vue';
import Spinner from "@/components/general/Spinner";
import { inject } from 'vue';

export default {
  name: 'ChatList',
  components: { ListItem, Spinner },
  emits: ['update:search', 'select-conversation'],
  props: {
    messages: {
      type: Array,
      required: true,
    },
    search: { type: String },
    spinner: { type: Boolean }
  },
  setup() {
    const mobileLayout = inject("mobileLayout");
    const activePrescription = inject('prescription');
    const chatType = inject('chatType');
    const getChatTitle = () => {
      switch (chatType.value) {
        case 'all_chat': return 'Tutte le chat';
        case 'auth_user_chat': return 'Chat personali';
        case 'favorite_chat': return 'Preferiti';
        default: return "Recenti";
      }
    }

    return { mobileLayout, activePrescription, getChatTitle }
  }
}

</script>
<template>
  <div class="collapse collapse-horizontal multi-collapse show h-100" :class="{ 'w-100': mobileLayout }"
    id="multiCollapseExample1">
    <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-light h-100">
      <span class="p-4 h-10r">
        <span class="fs-4 fw-semibold m-2">Tutte le chat</span>
        <form class="m-2">
          <input class="form-control rounded-3" type="text" placeholder="Cerca prescrizione o utente" aria-label="Search"
            @input="$emit('update:search', $event.target.value)">
        </form>
        <span class="fw-semibold m-3">{{ getChatTitle() }}</span>
      </span>
      <div class="list-group list-group-flush border-bottom scrollarea px-3 overflow-auto">
        <template v-if="$props.messages.length">
          <ListItem v-for="(message, index) in $props.messages" :key="index" :message="message" :active="message.name === activePrescription.number_text"
          :data-bs-toggle="mobileLayout ? 'collapse' : ''" :data-bs-target="mobileLayout ? '.multi-collapse' : ''"
          @click="$emit('select-conversation', message)" />
        </template>
        <template v-else-if="$props.spinner">
          <Spinner/>
        </template>
        <template v-else>
          <p class="m-auto">Nessuna chat trovata</p>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";
.h-10r {
  height: 10rem
}
.scrollarea {
  height: calc(100% - 14rem);
  min-width: 30rem;
}
@media (max-width: 991.98px) {
  .scrollarea {
  min-width: 22rem;
  height: calc(100% - 16rem);
}
}
</style>
