<script>
import ListGroup from './ListGroup.vue';

import { dateFormatter } from '@/use/utilities/time/dateFormatter';
import _head from "lodash/head";
import moment from "moment/moment";

import { inject, toRaw } from 'vue';
export default {
  name: 'SideComponent',
  components: {
    ListGroup
  },
  props: {
    messages: {
      type: Array,
      required: true,
    }
  },
  setup() {
    const mobileLayout = inject("mobileLayout");

    const getDateTimeLastMessage = (messages) => {
      // Reverse array.
      let messagesOrdered = toRaw(messages).reverse();
      // Exclude message has empty body.
      messagesOrdered = messagesOrdered.filter((message) => {
        return message.body !== null;
      });

      // Show date is same or before today.
      let messageCreatedAt = moment(_head(messagesOrdered).created_at).format("YYYY-MM-DD HH:mm:ss");
      let now = moment().format("YYYY-MM-DD HH:mm:ss");

      if (moment(messageCreatedAt).isBefore(now)) {
        return dateFormatter(_head(messagesOrdered).created_at);
      } else {
        // Show only time... it's today.
        return dateFormatter(_head(messagesOrdered).created_at, {onlyDate: false, onlyTime: true, dash: false, iso: false});
      }
    }

    return {
      mobileLayout,
      getDateTimeLastMessage
    }
  }
}
</script>
<template>
  <div class="collapse collapse-horizontal multi-collapse show h-100 overflow-auto" :class="{ 'w-100': mobileLayout }"
    id="multiCollapseExample1">
    <button v-if="mobileLayout" class="btn btn-primary" type="button" data-bs-toggle="collapse"
      data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">
    </button>
    <ListGroup />
  </div>
</template>
<style>
.w-fit-content {
  width: fit-content;
}
</style>
